<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-card
    :flat="flat"
    class="d-inline-flex flex-column text-center pa-2"
    color="transparent"
    v-bind="$attrs"
  >
    <div
      v-if="title"
      class="mb-3"
    >
      <h4>
        {{ title }}
        <span
          v-if="required"
          class="error--text"
        >&nbsp;*
        </span>
      </h4>
    </div>
    <div
      ref="avatarContainer"
      class="mb-5"
    >
      <v-avatar
        :color="avatarColor"
        :size="getWidth"
      >
        <v-img
          v-if="value"
          :max-height="maxHeight"
          :max-width="maxWidth"
          :src="value"
          :width="getWidth"
          contain
        />
        <span
          v-if="label && !value"
          :style="`font-size:${Math.ceil(getWidth/2)}px;user-select: none;`"
          class="primary--text"
        >
          {{ label.slice(0, 1).toUpperCase() }}
        </span>
      </v-avatar>
    </div>
    <div
      v-if="Boolean(getError)"
      class="error--text text-body-2 py-2"
    >
      {{ getError }}
    </div>
    <div>
      <app-btn
        v-bind="$attrs"
        @click="clickFileInput"
      >
        <v-icon left>
          image
        </v-icon>
        {{ btnLabel }}
      </app-btn>
      <input
        ref="inputFile"
        accept="image/*"
        class="input-file"
        type="file"
        @change="setupCropper($event.target)"
      >
    </div>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
    >
      <v-card
        flat
        tile
      >
        <v-card-title />
        <v-card-text class="text-center">
          <v-container>
            <v-row
              align="center"
              justify="center"
            >
              <v-col cols="12">
                <div class="mb-2">
                  <img
                    ref="source"
                    :height="AppIsSmall ? 200 : 400"
                    :src="objectUrl"
                    alt=""
                    class="image-preview elevation-3"
                  >
                </div>
                <div class="text-center">
                  <v-divider />
                  <v-btn
                    icon
                    min-width="auto"
                    small
                    @click="resetCropper"
                  >
                    <v-icon>mdi-aspect-ratio</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    min-width="auto"
                    small
                    @click="rotateLeft"
                  >
                    <v-icon>mdi-rotate-left</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    min-width="auto"
                    small
                    @click="rotateRight"
                  >
                    <v-icon>mdi-rotate-right</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-spacer />
              <!--<v-col cols="12">
                <img
                  :src="previewCropped"
                  alt=""
                  class="image-preview elevation-5"
                >
              </v-col>-->
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <app-btn
            :loading="savingCropped"
            color="primary"
            @click="saveCropped"
          >
            {{ $t('save') }}
          </app-btn>
          <v-spacer />
          <app-btn
            :disabled="savingCropped"
            color="error"
            @click="closeDialog"
          >
            {{ $t('cancel') }}
          </app-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import debounce from 'lodash/debounce'
import Cropper from 'cropperjs'

export default {
  name: 'AvatarInput',
  props: {
    value: {
      type: [String, Object],
      default: () => undefined
    },
    title: {
      type: String,
      default: () => undefined
    },
    label: {
      type: String,
      default: () => undefined
    },
    btnLabel: {
      type: String,
      default () {
        // return this.$t('change')
        return this.$t('choose')
      }
    },
    fileName: {
      type: String,
      default: () => 'avatar'
    },
    url: {
      type: String,
      default: () => null
    },
    width: {
      type: [String, Number],
      default: () => undefined
    },
    maxWidth: {
      type: [String, Number],
      default: () => '100%'
    },
    maxHeight: {
      type: [String, Number],
      default: () => '100%'
    },
    flat: {
      type: Boolean,
      default: () => !0
    },
    aspectRatio: {
      type: [String, Number],
      default: () => 1
    },
    errors: {
      type: [Object, Array],
      default: () => undefined
    },
    required: {
      type: Boolean,
      default: () => !1
    },
    maxSize: {
      type: [String, Number],
      default: () => 10
    }
  },
  data () {
    return {
      dialog: !1,
      cropper: null,
      objectUrl: null,
      previewCropped: null,
      selectedFile: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 300),
      savingCropped: !1
    }
  },
  computed: {
    getWidth () {
      if (this.width !== undefined) {
        return this.width
      }

      let w = Math.ceil(this.$vuetify.breakpoint.width * 0.15)

      if (this.$refs.avatarContainer) {
        // console.log(123)
        w = Math.ceil(this.$refs.avatarContainer.clientWidth) * 0.8
        return w
      }

      if (this.AppIsSmall) {
        // console.log(123)
        w = Math.ceil(this.$vuetify.breakpoint.width * 0.15) + 50
      }

      if (w > 150) {
        return 150
      }
      return w
    },
    getError () {
      let e = this.errors
      let message = null
      if (e) {
        if (this.$helpers.isOnlyObject(e)) {
          e = e[this.fileName] || null
        }
        message = e?.length > 0 ? e[0] : null
      }
      return message
    }
  },
  beforeCreate () {
    this.$on('dialog', v => (this.dialog = v))
  },
  mounted () {
    // console.log(this.$refs)
  },
  methods: {
    resetCropper () {
      this.cropper.reset()
    },
    rotateLeft () {
      this.cropper.rotate(-90)
    },
    rotateRight () {
      this.cropper.rotate(90)
    },
    clearCropper () {
      if (this.cropper) {
        this.cropper.destroy()
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl)
      }

      this.cropper = null
      this.objectUrl = null
      this.previewCropped = null
      if (this.$refs.inputFile) {
        this.$refs.inputFile.value = null
      }
    },
    setupCropper (selectedFile) {
      try {
        selectedFile = selectedFile.files[0]
      } catch (e) {
        selectedFile = null
      }
      if (!selectedFile) {
        this.closeDialog()
        return
      }
      const megabyte = parseInt(this.maxSize) || 1
      const kilobytes = 1024
      const size = (selectedFile.size || 1) / kilobytes

      if (size > kilobytes * megabyte) {
        this.alertError(this.$t('messages.max_size', { size: megabyte }))
        return
      }
      // console.log(selectedFile)
      this.openDialog()

      this.$nextTick(() => {
        (this.objectUrl = window.URL.createObjectURL(selectedFile)) &&
        this.$nextTick(this.setupCropperInstance)
      })
    },
    setupCropperInstance () {
      this.cropper = new Cropper(this.$refs.source, {
        background: !1,
        cropBoxResizable: !1,
        cropBoxMovable: !0,
        autoCropArea: 1,
        center: !0,
        responsive: !0,
        minCropBoxHeight: 200,
        minCropBoxWidth: 200,
        aspectRatio: this.aspectRatio,
        crop: this.debouncedUpdatePreview
      })
    },
    updatePreview (event) {
      const canvas = this.cropper.getCroppedCanvas()
      this.previewCropped = canvas.toDataURL('image/png')
    },
    clickFileInput () {
      this.$refs.inputFile.click()
    },
    openDialog () {
      this.clearCropper()
      this.dialog = !0
    },
    closeDialog () {
      this.clearCropper()
      this.dialog = !1
    },
    saveCropped () {
      this.savingCropped = !0
      const canvas = this.cropper.getCroppedCanvas()
      if (!this.url) {
        this.$emit('saved', canvas)
        this.savingCropped = !1
        return
      }

      canvas.toBlob(blob => {
        const formData = new FormData()
        formData.append(this.fileName, blob, `${this.fileName}.png`)
        this.$axios.post(this.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          this.$emit('saved', response)
        }).catch((error) => {
          this.$emit('error', error)
        }).finally(() => (this.savingCropped = !1))
      })
    }
  }
}
</script>
<style
  lang="sass"
  scoped
>
.input-file
  display: none

.app-col
  display: inline-block
  padding: 0 5px
  margin-bottom: 8px

.image-preview
  display: block
  max-height: 400px
  max-width: 400px
  min-height: 200px
  min-width: 200px
  width: 100%
  //height: 400px
  margin: auto

</style>
<style scoped>
@import '~cropperjs/dist/cropper.css';
</style>
