/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  datatable_search: 'Typing to search...',
  username: 'Login username',
  name: 'Your full name',
  mobile: 'Your mobile number',
  organization_name: 'Organization Name',
  commercial_register: 'Commercial Register number',
  vat_number: 'TAX number in your country',
  specifications_hint: 'After adding specifications, you can modify and attach photos, videos or documents to the client'
}
