/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import axios from 'axios'
import { Stub, StubUrl } from '../Stub'

const url = StubUrl('Notification')
export default {
  ...Stub(url()),
  indexUnreadCount: (config = {}) => axios.get(url('UnreadCount'), config),
  markAsRead: (id, config = {}) => axios.put(url(`${id}/read`), config)
}
