/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import { APP_ROUTES } from '@routes/list'

export default [
  {
    path: '/sp/:id',
    name: APP_ROUTES.public.viewSpecification,
    component: () => import(/* webpackChunkName: "ViewSpecificationPage" */ '@pages/ViewSpecification'),
    props: !0,
    meta: {
      hideBarBtn: !0
    }
  }
  // {
  //   path: '/AboutUs',
  //   name: APP_ROUTES.public.aboutUs,
  //   component: () => import(/* webpackChunkName: "AboutUsPage" */ '@pages/AboutUs')
  // },
  // {
  //   path: '/ContactUs',
  //   name: APP_ROUTES.public.contactUs,
  //   component: () => import(/* webpackChunkName: "ContactUsPage" */ '@pages/ContactUs')
  // },
  // {
  //   path: '/PrivacyPolicy',
  //   name: APP_ROUTES.public.privacyPolicy,
  //   component: () => import(/* webpackChunkName: "PrivacyPolicyPage" */ '@pages/PrivacyPolicy')
  // },
  // {
  //   path: '/TermsAndConditions',
  //   name: APP_ROUTES.public.termsAndConditions,
  //   component: () => import(/* webpackChunkName: "TermsAndConditionsPage" */ '@pages/TermsAndConditions')
  // }
]
