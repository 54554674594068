<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card fluid>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-dialog-loading.sync="formDialogLoading"
      :form-dialog.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :on-click-modal-activator="onClickModalActivator"
      :url="tableUrl"
      control-width="250px"
      excel
    >
      <template #filter="{datatableFilters}">
        <v-row>
          <app-text-input
            v-model="datatableFilters.id"
            clearable
            cols="12"
            hide-details
            name="invoice_id"
            sm="6"
          />
          <app-customers
            v-model="datatableFilters.customer_id"
            clearable
            cols="12"
            hide-details
            name="customer_id"
            sm="6"
          />
          <app-currencies
            v-model="datatableFilters.currency_id"
            clearable
            cols="12"
            hide-details
            name="currency_id"
            sm="6"
          />
          <app-text-input
            v-model="datatableFilters.mobile"
            clearable
            cols="12"
            hide-details
            name="mobile"
            sm="6"
          />
          <app-text-input
            v-model="datatableFilters.email"
            clearable
            cols="12"
            hide-details
            name="email"
            sm="6"
          />
        </v-row>
        <v-row>
          <app-date-picker
            v-model="datatableFilters.from_date"
            cols="12"
            hide-details
            name="from_date"
            sm="6"
          />
          <app-date-picker
            v-model="datatableFilters.to_date"
            cols="12"
            hide-details
            name="to_date"
            sm="6"
          />
        </v-row>
        <v-row>
          <app-date-picker
            v-model="datatableFilters.from_paid_at"
            cols="12"
            hide-details
            name="to_paid_at"
            sm="6"
          />
          <app-date-picker
            v-model="datatableFilters.to_paid_at"
            cols="12"
            hide-details
            name="to_paid_at"
            sm="6"
          />
          <app-filter-select
            v-model="datatableFilters.paid"
            clearable
            cols="12"
            md="6"
            name="paid"
          />
        </v-row>
      </template>

      <template #formDialog="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-row>
            <app-date-picker
              v-model="selectedItem.date"
              cols="12"
              name="date"
              required
              sm="6"
            />
            <app-customers
              v-model="selectedItem.customer_id"
              clearable
              cols="12"
              name="customer_id"
              sm="6"
            />
            <app-currencies
              v-model="selectedItem.currency_id"
              required
              cols="12"
              name="currency_id"
              sm="6"
            />
            <app-mobile-input
              v-model="selectedItem.mobile"
              clearable
              cols="12"
              name="mobile"
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.email"
              clearable
              cols="12"
              name="email"
              sm="6"
            />
            <!--<app-price-input
              v-if="selectedItem.id"
              v-model="selectedItem.total"
              cols="12"
              name="total"
              required
              sm="6"
            />-->
          </v-row>
          <v-row dense>
            <app-date-picker
              v-model="selectedItem.paid_at"
              clearable
              cols="12"
              name="paid_at"
              sm="6"
            />
            <app-switcher
              v-model="selectedItem.paid"
              cols="auto"
              name="paid"
            />
          </v-row>
          <v-row dense>
            <app-price-input
              v-model="selectedItem.discount"
              cols="12"
              name="discount"
              required
              sm="6"
            />
            <app-textarea-input
              v-model="selectedItem.notes"
              clearable
              cols="12"
              name="notes"
            />
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
            >
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <div class="overflow-x-auto">
                      <v-data-table
                        v-if="dialog"
                        ref="invoiceItems"
                        :headers="invoiceItemsHeaders"
                        :items="selectedItem.invoice_items"
                        class="invoice-items"
                        dense
                        disable-filtering
                        disable-pagination
                        disable-sort
                        fixed-header
                        hide-default-footer
                      >
                        <template #item.control="{index,item}">
                          <v-btn
                            v-show="index > 0"
                            class="mt-1"
                            color="error"
                            small
                            @click="removeInvoiceItem(index)"
                          >
                            <v-icon left>
                              mdi-close
                            </v-icon>
                            {{ parseAttribute('destroy') }}
                          </v-btn>
                          <v-btn
                            class="mt-1"
                            color="green"
                            small
                            @click="cloneInvoiceItem(item)"
                          >
                            <v-icon left>
                              mdi-content-copy
                            </v-icon>
                            {{ parseAttribute('clone') }}
                          </v-btn>
                        </template>

                        <template #item.brand_id="{index}">
                          <brands-container
                            v-if="dialog"
                            v-model="selectedItem.invoice_items[index].brand_id"
                            :brand-item-id="selectedItem.invoice_items[index].brand_item_id"
                            :brands="brands"
                            :index="index"
                            :items="brands"
                            :vid="`invoice_items.${index}.brand_id`"
                            :with-col="false"
                            name="brand_id"
                            required
                            @input:item="updateBrandItem($event,index)"
                          />
                        </template>

                        <template #item.vin="{index}">
                          <app-text-input
                            v-model="selectedItem.invoice_items[index].vin"
                            :vid="`invoice_items.${index}.vin`"
                            :with-col="false"
                            name="vin"
                          />
                        </template>

                        <!--<template #item.specifications="{index}">
                          <app-textarea-input
                            v-model="selectedItem.invoice_items[index].specifications"
                            :vid="`invoice_items.${index}.specifications`"
                            :with-col="false"
                            class="w-100 d-block"
                            clearable
                            name="specifications"
                          />
                        </template>-->

                        <template #item.color="{index}">
                          <app-combobox
                            v-model="selectedItem.invoice_items[index].color"
                            :items="colors"
                            :vid="`invoice_items.${index}.color`"
                            :with-col="false"
                            name="color"
                          />
                        </template>

                        <template #item.model="{index}">
                          <app-auto-select
                            v-model="selectedItem.invoice_items[index].model"
                            :items="getModels"
                            :vid="`invoice_items.${index}.model`"
                            :with-col="false"
                            name="model"
                            required
                          />
                        </template>

                        <template #item.country_of_origin="{index}">
                          <app-text-input
                            v-model="selectedItem.invoice_items[index].country_of_origin"
                            :vid="`invoice_items.${index}.country_of_origin`"
                            :with-col="false"
                            name="country_of_origin"
                          />
                        </template>

                        <template #item.price="{index}">
                          <app-price-input
                            v-model="selectedItem.invoice_items[index].price"
                            :vid="`invoice_items.${index}.price`"
                            :with-col="false"
                            name="price"
                            required
                          />
                        </template>

                        <!--<template #item.quantity="{index}">-->
                        <!--  <app-number-input-->
                        <!--    v-model="selectedItem.invoice_items[index].quantity"-->
                        <!--    :vid="`invoice_items.${index}.quantity`"-->
                        <!--    :with-col="false"-->
                        <!--    name="quantity"-->
                        <!--    required-->
                        <!--  />-->
                        <!--</template>-->

                        <template #item.price="{index}">
                          <app-price-input
                            v-model="selectedItem.invoice_items[index].price"
                            :vid="`invoice_items.${index}.price`"
                            :with-col="false"
                            name="price"
                            required
                          />
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              cols="12"
            >
              <v-toolbar>
                <v-toolbar-title>{{ $t('invoicePage.add_row') }}</v-toolbar-title>
                <v-spacer />
                <!--<v-btn-->
                <!--  class="mx-2"-->
                <!--  color="primary"-->
                <!--  @click="itemsDialog.open"-->
                <!--&gt;-->
                <!--  {{ parseAttribute('import') }}-->
                <!--</v-btn>-->
                <v-btn
                  class="mx-2"
                  color="accent"
                  @click="addInvoiceItem"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-btn
                :block="AppIsSmall"
                :loading="formDialogLoading"
                @click="submitClickForm"
              >
                {{ $t('save') }}
              </app-btn>
            </v-col>
          </v-row>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('show') && item.print_url"
          print
          @click="printItem(item)"
        />
        <!--<app-dt-btn-->
        <!--  v-if="userHasPermission('show') && item.print_url_normal"-->
        <!--  @click="printItem(item,!0)"-->
        <!--&gt;-->
        <!--  {{ $t('normal_print') }}-->
        <!--</app-dt-btn>-->
        <app-dt-btn
          v-if="userHasPermission('update')"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
  </app-card>
</template>
<script>
import { DatatableMixin, GetHeadersMixin, MetaInfoMixin } from '@mixins'
import BrandsContainer from '@components/base/resources/brand/BrandsContainer'

export default {
  name: 'Index',
  components: { BrandsContainer },
  helperApiName: 'invoice',
  mixins: [MetaInfoMixin, DatatableMixin, GetHeadersMixin],
  data () {
    return {
      modelPermission: 'Invoice',
      headers: [
        'invoice_id',
        'customer_id_to_string',
        'currency_id_to_string',
        'mobile',
        'email',
        'date_to_string',
        'total_to_string',
        'paid_to_string',
        'paid_at_to_string',
        'discount_to_string',
        'total_after_discount_to_string',
        // 'notes',
        'control'
      ],
      loading: !1,
      brands: [],
      brandItems: [],
      colors: [],
      itemsDialog: {
        value: !1,
        attachment: undefined,
        open: () => {
          this.itemsDialog = { ...this.itemsDialog, value: !0, attachment: undefined }
        },
        close: () => {
          this.itemsDialog = { ...this.itemsDialog, value: !1, attachment: undefined }
        }
      }
    }
  },
  computed: {
    invoiceItemsHeaders () {
      const h = [
        {
          text: 'control',
          value: 'control',
          width: '50px'
        },
        {
          text: '',
          value: 'brand_id'
        },
        // {
        //   text: '',
        //   value: 'specifications',
        //   width: this.AppIsSmall ? '100%' : 450
        // },
        {
          text: '',
          value: 'vin'
        },
        {
          text: '',
          value: 'color'
        },
        {
          text: '',
          value: 'model',
          width: this.AppIsSmall ? '100%' : 130
        },
        {
          text: '',
          value: 'country_of_origin',
          width: this.AppIsSmall ? '100%' : 130
        },
        // {
        //   text: '',
        //   value: 'quantity',
        //   width: this.AppIsSmall ? '100%' : 100
        // },
        {
          text: '',
          value: 'price'
        }
      ]
      return this.parseHeaders(h).map(e => ({ ...e, width: e.width || (e.value === 'control' ? undefined : (this.AppIsSmall ? '100%' : 200)) }))
    },
    defaultSelectedItem () {
      // console.log(2)
      return {
        // brand_id: null,
        order_by: 0,
        paid: !1,
        invoice_items: []
      }
    },
    getModels () {
      const v = []
      let i = 2010
      for (; i <= 2040; ++i) {
        v.push(i)
      }
      return v
    }
  },
  mounted () {
    this.checkPermission('index')
    this.$nextTick(() => {
      this.iniInvoiceItemUtilities()
    })
  },
  methods: {
    downloadImportItemsExample () {
      this.openWindow(this.apiService.utilities.downloadImportItemsExample)
    },
    importItems () {
      if (this.loading || !this.itemsDialog.attachment) return
      this.confirmMessage(this.$t('messages.import_invoice_items_confirm'), async () => {
        this.loading = !0
        const form = new FormData()
        this.$helpers.appendArray(form, { attachment: this.itemsDialog.attachment })
        // console.log(this.itemsDialog.attachment)
        this.apiService.invoice.importItems(form).then(({ _data }) => {
          // console.log(_data)
        }).catch((e) => {
          const message = e?._message || e?.message
          message && this.alertError(message)
        }).finally(() => (this.loading = !1))
      })
    },
    async iniInvoiceItemUtilities () {
      if (this.loading) return
      this.loading = !0
      this.apiService.utilities.invoiceItemUtilities().then(({ _data }) => {
        this.brands = _data?.brands || []
        this.brandItems = _data?.brand_items || []
        // console.log(_data)
        this.colors = _data?.colors || []
      }).catch(e => e).finally(() => (this.loading = !1))
    },
    getDefaultInvoiceItem () {
      return {
        brand_id: undefined,
        brand_item_id: undefined,
        specifications: undefined,
        exterior_color: undefined,
        interior_color: undefined,
        model: undefined,
        // quantity: 1,
        price: undefined,
        plates: 0,
        insurance: 0
      }
    },
    addInvoiceItem () {
      this.selectedItem = {
        ...this.selectedItem,
        invoice_items: [
          ...this.selectedItem.invoice_items,
          { ...this.getDefaultInvoiceItem() }
        ]
      }
      this.$nextTick(() => {
        if (this.$refs.invoiceItems?.$el?.firstChild) {
          this.$refs.invoiceItems.$el.firstChild.scrollLeft = 0
        }
      })
    },
    removeInvoiceItem (index) {
      if (this.selectedItem.invoice_items.length <= 1) return

      this.selectedItem = {
        ...this.selectedItem,
        invoice_items: this.selectedItem.invoice_items.filter((e, i) => i !== index)
      }
    },
    cloneInvoiceItem (item) {
      delete item.id
      this.selectedItem = {
        ...this.selectedItem,
        invoice_items: [
          ...this.selectedItem.invoice_items,
          { ...item }
        ]
      }
    },
    printItem (item, normal = !1) {
      this.openWindow(normal ? item.print_url_normal : item.print_url)
    },
    printInitInvoice (item) {
      this.openWindow(item.init_invoice_url)
    },
    updateBrandItem (item, index) {
      if (!item?.id) {
        const brand = this.selectedItem.invoice_items[index]?.brand_id
        item = this.brands.find(b => b.id === brand)?.brand_items?.find(e => e.id === item)
      }

      const update = (replace = !1) => {
        const selectedItem = { ...this.selectedItem }
        const items = [...selectedItem.invoice_items]
        let specifications = ''

        if (replace) {
          // specifications = (item?.name || '')
          specifications += (specifications ? '\n' : '') + (item?.specifications || '')
        } else {
          specifications += (items[index]?.specifications || '')
        }

        if (!specifications) {
          // specifications = item?.name || ''
          specifications += (specifications ? '\n' : '') + (item?.specifications || '')
        }
        items[index] = {
          ...items[index],
          specifications,
          brand_item_id: item.id
        }

        this.updateSelectedItem({ ...selectedItem, invoice_items: [...items] })
        // this.selectedItem.invoice_items = [...items]
      }

      update()
      // if (item?.specifications && this.selectedItem.invoice_items[index].specifications && item?.specifications !== this.selectedItem.invoice_items[index].specifications) {
      //   this.confirmMessage(this.$t('messages.specifications_update'), () => {
      //     update(!0)
      //   })
      // } else {
      //   update()
      // }
    },
    transformFormDatatable (form) {
      const keys = ['color']
      form.invoice_items.map(e => {
        keys.forEach(c => {
          if (typeof e[c] === 'object') {
            e[c] = e[c]?.text
          }
        })
        return e
      })
      return form
    },
    onClickModalActivator () {
      setTimeout(() => {
        this.addInvoiceItem()
      }, 90)
    }
  }
}
</script>

<style lang="scss">

.invoice-items {

  td:not(.v-data-table__mobile-row) {
    vertical-align: top !important;
  }

  .v-data-table__mobile-row,
  .v-data-table__mobile-row__cell {
    width: 100% !important;
  }

  .v-data-table__wrapper .v-data-table__mobile-row {
    width: 100% !important;
  }

  .v-data-table__wrapper .v-data-table__mobile-row > .v-data-table__mobile-row__header {
    display: none;
  }
}

</style>
