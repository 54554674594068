/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import messages from 'vuetify/src/locale/ar.ts'

if (!messages.dataTable) messages.dataTable = {}
if (!messages.dataTable.ariaLabel) messages.dataTable.ariaLabel = {}
messages.noDataText = 'لا توجد بيانات'
messages.dataTable.itemsPerPageText = 'عدد النتائج:'
messages.dataTable.sortBy = 'الفرز حسب'
messages.dataIterator.noResultsText = 'لا توجد بيانات'
messages.dataIterator.loadingText = 'تحميل...'
messages.dataTable.ariaLabel = {
  ...messages.dataTable.ariaLabel,
  activateNone: 'انقر لإزالة الفرز',
  activateDescending: 'انقر للترتيب تنازلياً',
  activateAscending: 'انقر للترتيب تصاعدياً'
}
export default messages
