<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <component
    :is="getComponentName"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'ColSpan',
  props: {
    lazyModel: {
      type: Boolean,
      default: () => false
    },
    withCol: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {}
  },
  computed: {
    getComponentName () {
      return this.withCol ? 'app-col' : 'span'
    },
    lazy: {
      get () {
        return this.lazyModel
      },
      set (v) {
        this.$emit('update:lazyModel', v)
      }
    }
  },
  mounted () {
  }
}
</script>
