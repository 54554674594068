<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      v-slot="v"
      ref="provider"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <slot name="top" />
      <!--<v-slider-->
      <!--  max="1000000"-->
      <!--  min="0"-->
      <!--  persistent-hint-->
      <!--  step="1000"-->
      <!--  v-bind="$attrs"-->
      <!--  :error="getErrorMessages.length>0"-->
      <!--/>-->
      <v-slider
        ref="input"
        :clearable="getClearable"
        :counter="counter"
        :counter-value="a => a ? a.length : 0"
        :disabled="disabled"
        :error="getErrorMessages.length>0"
        :error-count="getErrorCount"
        :error-messages="[...getErrorMessages,...v.errors]"
        :label="getLabel"
        :placeholder="getPlaceholder"
        :readonly="readonly"
        :value="value"
        thumb-label="always"
        v-bind="$attrs"
        v-on="{...$listeners,on:input}"
      />
      <slot />
      <slot name="bottom" />
    </ValidationProvider>
  </app-col-span>
</template>

<script>

import { InputsMixin } from '@mixins'

export default {
  name: 'SliderInput',
  mixins: [InputsMixin],
  methods: {
    input (v) {
      this.$emit('input', v)
    }
  }
}
</script>
