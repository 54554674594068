<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-auto-select
    :chips="getChips"
    :clearable="clearable"
    :deletable-chips="getDeletableChips"
    :hide-no-data="getHideNoData"
    :hide-selected="!showSelected"
    :item-text="getItemText"
    :items="items"
    :lazy-model.sync="isActive"
    :loading="loading"
    :multiple="multiple"
    :prefix="getPrefix"
    :small-chips="getSmallChips"
    :value="value"
    :vid="vid"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </app-auto-select>
</template>

<script>

export default {
  events: ['set-items'],
  name: 'AxiosSelect',
  props: {
    value: {},
    rules: {},
    vid: {
      type: String,
      default: () => undefined
    },
    prefix: {
      default: () => undefined
    },
    country: {
      type: Boolean,
      default: () => !1
    },
    smallChips: {
      type: Boolean,
      default: () => undefined
    },
    hideNoData: {
      type: Boolean,
      default: () => undefined
    },
    deletableChips: {
      type: Boolean,
      default: () => undefined
    },
    chips: {
      type: Boolean,
      default: () => undefined
    },
    multiple: {
      type: Boolean,
      default: () => !1
    },
    showSelected: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    axiosUrl: {
      type: [String, Function],
      required: !1,
      default: () => undefined
    },
    axiosMethod: {
      type: String,
      default: () => 'get'
    },
    axiosData: {},
    axiosParams: {},
    axiosConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isActive: false,
      loading: !1,
      items: [],
      fetched: false
    }
  },
  computed: {
    getAxiosUrl () {
      return this.axiosUrl
    },
    getDeletableChips () {
      return this.deletableChips === undefined ? this.multiple : this.deletableChips
    },
    getSmallChips () {
      return this.smallChips === undefined ? this.getChips : this.smallChips
    },
    getChips () {
      // return this.chips
      return this.chips === undefined ? this.multiple : this.chips
    },
    getHideNoData () {
      return this.hideNoData
      // return this.hideNoData === undefined ? this.multiple : this.hideNoData
    },
    getItems () {
      return this.items
    },
    isCountry () {
      return this.country
    },
    getPrefix () {
      // try{
      //   if(this.isCountry) {
      //     const i = this.getItems.find( item => item.value === this.value)
      //     return  i?.key || this.prefix
      //   }
      // }catch (e) {
      //   console.log(e)
      // }
      return this.prefix
    },
    getItemText () {
      return undefined
    }
  },
  watch: {
    items (v) {
      this.$emit('set-items', v)
    },
    value (n, o) {
      const v = this.items.find(e => e.value === n)
      this.$emit('set-item', v)
    }
  },
  mounted () {
    this.$nextTick(() => this.fetchData())
  },
  methods: {
    setLoading (v) {
      this.loading = v
    },
    setItems (v) {
      this.items = v
    },
    getRequest (url) {
      return this.$axios.request({
        url,
        method: this.axiosMethod,
        data: this.axiosData,
        params: this.axiosParams,
        ...this.axiosConfig
      })
    },
    fetchData () {
      if (this.loading || this.fetched) return
      this.setLoading(!0)

      const func = () => {
        let axios = this.$helpers.isFunction(this.getAxiosUrl) ? this.getAxiosUrl() : this.getAxiosUrl
        this.$helpers.isString(axios) && (axios = this.getRequest(axios))
        if (!axios) {
          this.alertError(this.$t('messages.error'))
          return
        }
        // console.log(axios,this.getAxiosUrl)
        this.$nextTick(() => axios.then((request) => {
          const { data } = request || {}
          if (data && data.data && this.$helpers.isOnlyArray(data.data)) {
            this.complete(data.data)
          }
          // return request
        }).catch(e => e).finally(this.finally))
      }
      func()
    },
    complete (data) {
      this.setItems(data)
      this.fetched = !0
    },
    finally () {
      this.setLoading(!1)
    }
  }
}
</script>
