/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  copyright: 'Copyright © {date} - All rights reserved {name}',
  ar: 'العربية',
  en: 'English',
  control: '',
  contactUs: 'Contact us',
  aboutUs: 'About us',
  privacyPolicy: 'Privacy & Policy',
  termsAndConditions: 'Terms & Conditions',
  clear: 'Clear',
  undo: 'Undo',
  send: 'Send',
  done: 'Done',
  yes: 'Yes',
  no: 'No',
  more: 'More',
  cancel: 'Cancel',
  close: 'Close',
  store: 'Create',
  create: 'Create',
  update: 'Update',
  destroy: 'Destroy',
  none: 'None',
  search: 'Search',
  save: 'Save',
  change: 'Change',
  location: 'Location',
  load_more: 'Load More',
  login: 'Login',
  logout: 'Logout',
  dashboard: 'Dashboard',
  home: 'Home',
  register: 'Register',
  verify: 'Verify',
  update_password: 'Update Password',
  create_account: 'Create Account',
  options: 'Options',
  export_pdf: 'Export as PDF',
  export_excel: 'Export as Excel',
  refresh_table: 'Refresh Data',
  close_window: 'Close',
  show_permissions: 'Show Permissions',
  select_all: 'Select All',
  statistics: 'Statistics',
  print: 'Print',
  do_search: 'Search',
  my_account: 'My Account',
  active_account: 'Activate the account',
  inactive_account: 'Disable Account',
  sar: 'SAR',
  filter: 'Filter',
  available: 'Available',
  my_location: 'My Location',
  show_location: 'Show Project Location',
  notifications: 'Notifications',
  phone: 'Phone',
  contact_data: 'Contact Data',
  settings: 'Settings',
  share: 'Share',
  users: 'Users',
  customers: 'Customers',
  lightTheme: 'Light Theme',
  darkTheme: 'Dark Theme',
  view: 'View',
  show_all: 'Show all',
  none_notifications: 'There is no notifications',
  upload: 'Upload',
  show: 'Show',
  back: 'Back',
  choose: 'Choose',
  follow_us: 'Follow Us',
  approve: 'Approve',
  pending: 'Pending',
  confirm: 'Confirm',
  invoice: 'Invoice',
  day: 'Day',
  days: 'Days',
  comboboxNoData: 'Select {search}',
  save_signature: 'Save Signature',
  import: 'Import',
  videos: 'Videos',
  files_show: 'PDF',
  download: 'Download',
  export: 'Export',
  copy_url: 'Copy Url',
  clone: 'Clone',
  copy_specifications: 'Copy Url',
  copy_specifications_with_name: 'Copy Url with name',
  normal_print: 'Normal Print',
  init_invoice: 'Initial Invoice'
}
