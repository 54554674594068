<template>
  <v-container fluid>
    <v-row
      dense
      justify="start"
    >
      <v-col cols="12">
        <h3 :class="{'error--text':errors.signature && errors.signature.length>0 }">
          {{ parseAttribute('signature') }}
        </h3>
        <label
          v-if="errors.signature"
          class="error--text"
        >{{ errors.signature[0] }}
        </label>
      </v-col>
      <v-col
        align-self="center"
        class="justify-center align-center text-center"
        cols="auto"
      >
        <div class="d-inline-flex border mb-2">
          <v-card max-width="400">
            <vue-signature-pad
              ref="signature"
              :options="{onEnd:signatureOnEnd}"
              height="200px"
              width="100%"
            />
          </v-card>
        </div>
        <v-row
          dense
          align="center"
          justify="center"
        >
          <v-col cols="auto">
            <app-btn
              :loading="loading"
              @click="saveSignature(!1)"
            >
              {{ $t('save_signature') }}
            </app-btn>
          </v-col>
          <v-col cols="auto">
            <app-btn
              :disabled="loading"
              color="secondary"
              @click="undoSignature"
            >
              {{ $t('undo') }}
            </app-btn>
          </v-col>
          <v-col cols="auto">
            <app-btn
              :disabled="loading"
              color="error"
              @click="clearSignature"
            >
              {{ $t('clear') }}
            </app-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SignatureForm',
  props: {
    errors: {
      type: Object,
      default: () => ({})
    },
    preview: {
      type: String,
      default: () => null
    },
    signature: {
      type: String,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: () => !1
    }
  },
  data: () => ({
    previewData: null
  }),
  mounted () {
    // console.log(this.$refs)
  },
  methods: {
    signatureOnEnd (event) {
      this.$nextTick(() => this.saveSignature(!0))
    },
    undoSignature () {
      this.$refs.signature.undoSignature()
      this.$nextTick(() => this.saveSignature(!0))
    },
    clearSignature () {
      this.$refs.signature.clearSignature()
      this.$nextTick(() => this.saveSignature(!0))
    },
    saveSignature (preview = !1) {
      const { isEmpty, data } = this.$refs.signature.saveSignature()
      if (preview === !1 && isEmpty) {
        this.alertError(this.$t('messages.signature_is_empty'))
        return
      }
      this.previewData = data
      this.$emit('preview', data)
      this.$emit('update:preview', data)
      if (preview === !0) {
        return
      }
      // console.log(data)
      this.$emit('signature', data)
      this.$emit('update:signature', data)
      // this.alertSuccess(this.$t('messages.signature_saved'))
    }

  }
}
</script>
