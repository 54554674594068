/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  copyright: '{name} - جميع الحقوق محفوظة © {date}',
  ar: 'العربية',
  en: 'English',
  control: '',
  contactUs: 'تواصل معنا',
  aboutUs: 'من نحن',
  privacyPolicy: 'سياسة الخصوصية',
  termsAndConditions: 'الشروط والأحكام',
  clear: 'مسح',
  undo: 'تراجع',
  send: 'إرسال',
  done: 'موافق',
  yes: 'نعم',
  no: 'لا',
  more: 'المزيد',
  cancel: 'إلغاء',
  close: 'إغلاق',
  store: 'إضافة',
  create: 'إضافة',
  update: 'تعديل',
  destroy: 'حذف',
  none: 'لا يوجد',
  search: 'البحث',
  save: 'حفظ',
  change: 'تعديل',
  location: 'الموقع',
  load_more: 'إظهار المزيد',
  login: 'تسجيل الدخول',
  logout: 'تسجيل الخروج',
  dashboard: 'لوحة التحكم',
  home: 'الرئيسية',
  register: 'حساب جديد',
  verify: 'تحقق',
  update_password: 'تعديل كلمة المرور',
  create_account: 'إنشاء الحساب',
  options: 'خيارات',
  export_pdf: 'تصدير البيانات PDF',
  export_excel: 'تصدير البيانات Excel',
  refresh_table: 'تحديث البيانات',
  close_window: 'إغلاق',
  show_permissions: 'الصلاحيات',
  select_all: 'تحديد الكل',
  statistics: 'الإحصائيات',
  print: 'طباعة',
  do_search: 'بحث',
  my_account: 'حسابي',
  active_account: 'تفعيل الحساب',
  inactive_account: 'تعطيل الحساب',
  sar: 'ر.س.',
  filter: 'تصفية',
  available: 'متاح',
  my_location: 'موقعي',
  show_location: 'الموقع الحالي',
  notifications: 'الإشعارات',
  phone: 'الهاتف',
  contact_data: 'بيانات التواصل',
  settings: 'الإعداد',
  share: 'مشاركة',
  users: 'المستخدمون',
  customers: 'العملاء',
  lightTheme: 'المظهر الفاتح',
  darkTheme: 'المظهر الليلي',
  view: 'عرض',
  show_all: 'عرض الكل',
  none_notifications: 'لا توجد إشعارات',
  upload: 'رفع',
  show: 'عرض',
  back: 'رجوع',
  choose: 'اختيار',
  follow_us: 'تابعنا',
  approve: 'قبول',
  pending: 'تعليق',
  confirm: 'تأكيد',
  invoice: 'فاتورة',
  day: 'يوم',
  days: 'أيام',
  comboboxNoData: 'اختيار {search}',
  save_signature: 'حفظ التوقيع',
  import: 'استيراد',
  videos: 'الفيديوهات',
  files_show: 'PDF',
  download: 'تحميل',
  export: 'تصدير',
  copy_url: 'نسخ الرابط',
  clone: 'نسخ',
  copy_specifications: 'نسخ الرابط',
  copy_specifications_with_name: 'نسخ الرابط مع الاسم',
  normal_print: 'طباعة عادية',
  init_invoice: 'الفاتورة المبدئية'
}
