/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  data: () => ({
    countriesMixin: []
  }),
  methods: {
    setCountriesMixin (v) {
      this.countriesMixin = v
    },
    changeCountriesMixin (v) {
      if (v) {
        // const form = this[this.countriesMixinFormKey]
        this[this.countriesMixinFormKey].city_id = null
        this.$emit('input', this[this.countriesMixinFormKey])
      }
      // console.log(v)
    }
  },
  computed: {
    countriesMixinFormKey () {
      return 'form'
    },
    getCountryMixin () {
      const id = this[this.countriesMixinFormKey]?.country_id
      if (!id) return undefined
      return this.countriesMixin.find(e => e.id === id)
    },
    getCitiesMixin () {
      return this.getCountryMixin?.cities || []
    }
  }
}
