<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-footer
    :apps="$vuetify.breakpoint.mdAndUp"
    class="d-print-none"
    color="footer"
    dark
    padless
  >
    <v-container
      class="text-center"
      fluid
    >
      <span
        class="text-body-2 copyright"
      >{{ copyright }}
      </span>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'MainFooter'
}
</script>

<style scoped>
/*.copyright{*/
/*  color: var(--v-footer-lighten5);*/
/*}*/
</style>
