/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import { Stub, StubUrl } from '../Stub'

const url = StubUrl('Utilities/Country')
export default {
  ...Stub(url())
}
