/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import { Stub, StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Utilities/Specification')
export default {
  ...Stub(url()),
  publicShow (id, params = {}, config = {}) {
    const u = `Static/Specification/${id}/show`
    return axios.get(u, { params, ...config })
  },
  export (id, ...args) {
    const u = url(`${id}/Export`)
    return axios.get(u, ...args)
  }
}
