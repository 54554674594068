/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  methods: {
    openDownloadApp (payload = {}) {
      // console.log(payload, this.$route)
    },
    downloadGooglePlay (payload = {}) {
      const url = this.AppSettings?.play_store_url
      if (url) {
        window.open(url, 'windowDownload')
      }
    },
    downloadAppStore (payload = {}) {
      const url = this.AppSettings?.app_store_url
      if (url) {
        window.open(url, 'windowDownload')
      }
    }
  },
  computed: {}
}
