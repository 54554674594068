/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import config from '@config'

import i18n from '@plugins/vuetify-vue-i18n'
import messages from '@plugins/vuetify-vue-i18n/register'

Vue.use(Vuetify)
const defaultLocale = config.locale.defaultLocale
const storageItem = window?.localStorage?.getItem(config.theme.storageKey)

const storageThemeDark = storageItem ? JSON.parse(storageItem).value : config.theme.dark

const opts = {
  breakpoint: {
    thresholds: {
      // xs: 340,
      // sm: 540,
      // md: 800,
      // lg: 1280,
    },
    scrollBarWidth: 0,
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  },
  rtl: config.theme.rtl,
  theme: {
    dark: storageThemeDark,
    options: {
      customProperties: !0,
      variations: !0
    },
    themes: { ...config.theme.themes }
  },
  lang: {
    current: defaultLocale,
    defaultLocale,
    t: (key, ...params) => i18n.t(key, params),
    locales: messages
  }

}
export default new Vuetify(opts)
