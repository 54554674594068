/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import qs from 'qs'
import Vue from 'vue'

Vue.prototype.$qs = qs
window.qs = qs

export default qs
