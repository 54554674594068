<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-menu
    v-model="menu"
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    :left="!AppRtl"
    :right="AppRtl"
    bottom
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          :color="badgeColor"
          left
          overlap
        >
          <template
            v-if="UnreadAppNotifications>0"
            #badge
          >
            <span>{{ UnreadAppNotifications }}</span>
          </template>
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <template v-if="FetchingAppNotifications">
      <v-card>
        <v-skeleton-loader
          :loading="FetchingAppNotifications"
          :min-width="minWidth"
          type="list-item-avatar-three-line"
        />
        <v-skeleton-loader
          :loading="FetchingAppNotifications"
          :min-width="minWidth"
          type="list-item-avatar-three-line"
        />
        <v-skeleton-loader
          :loading="FetchingAppNotifications"
          :min-width="minWidth"
          type="list-item-avatar-three-line"
        />
      </v-card>
    </template>
    <template v-else>
      <v-list
        :min-width="minWidth"
        :width="width"
        class="py-0"
        dense
      >
        <template v-if="hasNotifications">
          <template v-for="(notification) in AppNotifications">
            <v-list-item
              :key="notification.id"
              link
              two-line
              @click="clickNotification(notification)"
            >
              <!--<v-list-item-avatar>-->
              <!--  <v-icon>{{ notification.icon }}</v-icon>-->
              <!--</v-list-item-avatar>-->
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters>
                    <v-col class="d-flex">
                      <span
                        v-if="notification.unread"
                        class="error--text "
                        style="font-size: 20px"
                      >*
                      </span>
                      {{ notification.subject }}
                    </v-col>
                    <v-col cols="auto">
                      <span>{{ notification.created_at_to_string }}</span>
                    </v-col>
                  </v-row>
                </v-list-item-title>
                <v-list-item-subtitle>{{ notification.content }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="notification.id+'-divider'"
            />
          </template>
          <template v-if="!isRoute(APP_ROUTES.user.notifications)">
            <v-divider />
            <v-list-item
              class="justify-center"
              color="primary"
              link
              @click="goToNotifications"
            >
              <span class="pb-2 primary--text">{{ $t('show_all') }}</span>
            </v-list-item>
          </template>
        </template>
        <template v-else>
          <v-list-item class="mb-0 justify-center">
            <span class="mb-1">{{ $t('none_notifications') }}</span>
          </v-list-item>
        </template>
      </v-list>
    </template>
    <v-dialog
      v-model="notificationDialogValue"
      max-width="720"
      no-click-animation
      persistent
    >
      <notification-view
        v-if="notificationDialogItem"
        :notification="notificationDialogItem"
        @close="closeNotificationDialog"
      />
    </v-dialog>
  </v-menu>
</template>

<script>

import NotificationView from '@components/base/app/NotificationView'
import { NotificationDialogMixin, NotificationsMixin } from '@mixins'

export default {
  name: 'NotificationsMenu',
  components: { NotificationView },
  mixins: [NotificationDialogMixin, NotificationsMixin],
  data () {
    return {
      menu: !1,
      closeOnContentClick: !1,
      minWidth: 300,
      width: 300
    }
  },
  computed: {
    closeOnClick () {
      return !0
      // return this.notifications.length < 1
    },
    badgeColor () {
      return this.UnreadAppNotifications > 0 ? 'error' : 'transparent'
    },
    hasNotifications () {
      return this.AppNotifications.length > 0
    }
  },
  mounted () {
    // this.initAppNotifications()
  },
  beforeDestroy () {
    // this.clearAppNotifications()
  },
  methods: {
    clickNotification (notification) {
      this.showDialog(notification)
    },
    showDialog (notification) {
      this.notificationDialogItem = notification
      this.$nextTick(() => {
        this.notificationDialogValue = !0
        this.$nextTick(() => {
          if (!notification.read) {
            notification.read = !0
            notification.unread = !1
            // --this.UnreadAppNotifications
          }
        })
      })
    },
    goToNotifications () {
      this.menu = !1
      this.navigate(this.APP_ROUTES.user.notifications)
    }
  }
}
</script>
