<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-card
    :color="getColor"
    :dark="dark"
    height="100"
    min-width="250"
    v-bind="$attrs"
    width="250"
    v-on="$listeners"
  >
    <v-container
      class="pa-0 pb-1 fill-height"
      fluid
    >
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          class="ps-2"
          cols="auto"
        >
          <v-icon>
            {{ getIcon }}
          </v-icon>
        </v-col>
        <v-col class="ps-2">
          <v-row no-gutters>
            <v-col cols="12">
              <span class="font-weight-bold">{{ text }}</span>
            </v-col>
            <v-col
              v-if="value !== undefined"
              cols="12"
            >
              <span
                class="headline card-value"
              >{{ $helpers.toNumberFormat(value) }}
              </span>
            </v-col>
            <v-col
              v-if="count !== undefined"
              cols="12"
            >
              <span
                class="text-body-1 card-value"
              >{{ count }}
              </span>
            </v-col>
            <v-col
              v-if="amount"
              cols="12"
            >
              <span
                class="text-body-1 card-value"
              >{{ amount }}
              </span>
            </v-col>
            <!--<v-col-->
            <!--  v-if="sales"-->
            <!--  cols="12"-->
            <!--&gt;-->
            <!--  <span-->
            <!--    class="headline card-value"-->
            <!--  >{{ $helpers.toNumberFormat(sales) }}-->
            <!--  </span>-->
            <!--</v-col>-->
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="link"
        align="center"
        justify="center"
        no-gutters
      >
        <v-col
          align-self="center"
          class="px-2"
          cols="auto"
        >
          <v-btn
            :disabled="!link"
            :to="link"
            color="white"
            dark
            small
          >
            <span class="black--text card-text">{{ $t('replace.browse', { v: text }) }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <!--
      <v-divider class="my-1" />
      <v-row
        justify="center"
        no-gutters
      >
        <v-col cols="12">
          <v-btn
            :disabled="!link"
            :to="link"
            block
            small
            text
          >
            <template v-if="link">
              <v-icon>open_in_new</v-icon>
              <span class="ms-1 text-body-2">{{ $t('replace.browse', { v: text }) }}</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>-->
    </v-container>
  </v-card>
</template>
<script>

export default {
  name: 'StaticCard',
  props: {
    dark: {
      type: Boolean,
      default: () => !0
    },
    color: {
      type: String,
      default: () => undefined
    },
    text: {
      type: String,
      default: () => ''
    },
    value: {
      type: [String, Number],
      default: () => undefined
    },
    icon: {
      type: String,
      default: () => undefined
    },
    amount: {
      type: [String, Number],
      default: () => undefined
    },
    count: {
      type: [String, Number],
      default: () => undefined
    },
    link: {
      type: [String, Object, Function, Object],
      default: () => undefined
    }
  },
  data: () => ({
    colors: []
  }),
  computed: {
    getColor () {
      const l = [
        '#C7A359',
        '#c05959',
        '#243253',
        '#6cc6ce',
        '#16a24c'
      ]
      return this.color || this.$_.sample(l)
    },
    getIcon () {
      return this.icon || 'trending_up'
    }
  },
  mounted () {
  }
}
</script>
<style>
.card-value {
  font-size: 22px;
}

.card-text {
  font-size: 12px;
}
</style>
