<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <router-view
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import LayoutRouterView from '@/layouts/LayoutRouterView'

export default {
  name: 'App',
  components: { LayoutRouterView },
  mounted () {
  }
}
</script>

<style lang="scss">
.user-select-none {
  user-select: none !important;
}

/* durations and timing functions.              */
.slide-fade-enter-active {
  //transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  //transform: translateX(-10px);
  //transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transition: all .8s ease;
  //opacity: 0.5;
}

.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  opacity: 0.3;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transform: translateY(100px);
  //transform: translateX(100px);
  //transform: translate(30px);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: var(--v-accent-base);
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--v-accent-base);
}

.v-application .v-toolbar.primary .v-btn__content,
.v-application .v-toolbar.primary .v-toolbar__title,
.v-application .v-toolbar.toolbar,
.v-application .v-toolbar.toolbar .v-btn > .v-btn__content .v-icon {
  color: #ffff !important;
}

.v-application--is-rtl input[myth-ltr="true"],
.v-application--is-rtl textarea[myth-ltr="true"] {
  direction: ltr !important;
}
</style>
