<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <base-btn
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </base-btn>
  <!--<app-tooltip-->
  <!--  v-if="!tooltipDisabled"-->
  <!--  :disabled="tooltipDisabled"-->
  <!--  :text="getTooltip"-->
  <!--  :tooltip-bottom="tooltipBottom"-->
  <!--  v-bind="$attrs"-->
  <!--  v-on="$listeners"-->
  <!--&gt;-->
  <!--  <base-btn-->
  <!--    v-bind="$attrs"-->
  <!--    v-on="$listeners"-->
  <!--  >-->
  <!--    <slot />-->
  <!--  </base-btn>-->
  <!--</app-tooltip>-->
  <!--<base-btn-->
  <!--  v-else-->
  <!--  v-bind="$attrs"-->
  <!--  v-on="$listeners"-->
  <!--&gt;-->
  <!--  <slot />-->
  <!--</base-btn>-->
</template>

<script>
export default {
  name: 'Btn',
  components: {
    BaseBtn: () => import('./BaseBtn')
  },
  props: {
    tooltip: {
      type: String,
      default: () => undefined
    },
    tooltipBottom: {
      type: Boolean,
      default: () => !1
    }
  },
  computed: {
    getTooltip () {
      if (this.tooltip) return this.parseAttribute(this.tooltip)
      return null
    },
    tooltipDisabled () {
      return this.getTooltip === null || this.getTooltip === undefined || this.AppIsSmall
    }
  },
  mounted () {
    // console.log(this.$attrs)
  }
}
</script>
