<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-row>
    <app-col
      cols="12"
      dense
    >
      <app-row align="center">
        <app-col
          class="justify-content-centers"
          cols="12"
          md="6"
        >
          <h2 class="mb-3">
            {{ parseAttribute('image') }}
          </h2>
          <v-avatar
            :color="avatarColor"
            size="170"
          >
            <v-img
              :src="blobAvatar"
              contain
            />
          </v-avatar>
        </app-col>
      </app-row>
      <app-row>
        <app-file-input
          :label="label"
          :value="value"
          accept="image/x-png,image/gif,image/jpeg"
          clearable
          cols="12"
          lg="4"
          md="6"
          name="image"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </app-row>
    </app-col>
  </app-row>
</template>

<script>
export default {
  name: 'InputAvatar',
  props: {
    value: {},
    blobAvatar: {},
    label: {}
  }
}
</script>
