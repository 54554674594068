/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import CKEditor from 'ckeditor4-vue'
import Vue from 'vue'

Vue.use(CKEditor)
Vue.prototype.$CKEditor = CKEditor
export default CKEditor
