/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import _ from 'lodash'
import { createNamespacedHelpers, mapGetters, mapMutations } from 'vuex'

const { mapActions } = createNamespacedHelpers('snackbar')

export default {
  methods: {
    alertMessage (text = '', type = '', callback = null) {
      if (_.isObject(text)) {
        type = text.type || null
        callback = text.callback || null
        text = text.text
      }

      if (type && typeof type !== 'string') {
        callback = type
        type = null
      }
      return mapActions(['showSnackbar']).showSnackbar.call(this, { text, type, callback })
    },
    alertError (text = '', callback = null) {
      return this.alertMessage(text, 'error', callback)
    },
    alertSuccess (text = '', callback = null) {
      return this.alertMessage(text, 'success', callback)
    },
    toast (text = '', type = 'normal', toast = !0) {
      return mapActions(['showSnackbar']).showSnackbar.call(this, { text, type, toast })
    },
    confirmMessage (text = '', callback = null, reject = null, type = null) {
      if (typeof text === 'function') {
        callback = text
        text = this.$t('messages.are_you_sure')
      }
      return mapActions(['showSnackbar']).showSnackbar.call(this, { text, type, callback, confirm: !0, reject })
    }
  },
  computed: {
    AppSnackbar: {
      set (value) {
        mapMutations('snackbar', ['setShow']).setShow.call(this, value)
      },
      get () {
        return mapGetters('snackbar', ['getShow']).getShow.call(this)
      }
    }
  }
}
