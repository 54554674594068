<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-row
    dense
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-col
      v-for="q in getRows"
      :key="q"
      v-bind="COL_SIZES"
    >
      <v-sheet>
        <v-skeleton-loader
          :type="type"
          v-bind="$attrs"
        />
      </v-sheet>
    </v-col>
    <slot />
  </v-row>
</template>

<script>
export default {
  name: 'SkeletonRow',
  props: {
    type: {
      type: String,
      default: () => 'card'
    },
    skeletonRows: {
      type: [String, Number],
      default: undefined
    }
  },
  computed: {
    getRows () {
      return this.skeletonRows || this.SKELETON_COLS
    }
  }
}
</script>
