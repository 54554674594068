<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-list-group
    v-if="item.items"
    :active-class="activeClasses"
    :no-action="false"
    :prepend-icon="!AppIsSmall ? item.icon : null"
    :prepend-iconf="item.icon"
    :ssub-group="subGroup"
    :value="getActiveList"
  >
    <template #activator>
      <v-list-item-title v-if="subGroup">
        <v-badge
          v-if="item.badge"
          :content="item.badge"
          color="error"
        >
          {{ item.title }}
        </v-badge>
        <span
          v-else
          v-text="item.title"
        />
      </v-list-item-title>
      <v-list-item-title v-else>
        {{ item.title }}
      </v-list-item-title>
    </template>
    <app-list-item
      v-for="(sub, i) in item.items"
      :key="i"
      :item="sub"
    />
  </v-list-group>
  <v-divider
    v-else-if="item.divider"
  />
  <v-list-item
    v-else
    :active-class="activeClasses"
    :class="{'text-center': item.center}"
    :to="route(item.name)"
    link
    tag="div"
    @click="itemClick(item)"
  >
    <v-list-item-icon v-if="item.icon&&!AppIsSmall">
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>
      <v-badge
        v-if="item.badge"
        :content="item.badge"
        color="error"
      >
        {{ item.title }}
      </v-badge>
      <span v-else>
        {{ item.title }}
      </span>
    </v-list-item-title>
  </v-list-item>
</template>

<script>

export default {
  name: 'ListItem',
  props: {
    item: {
      type: Object,
      required: !0,
      default: () => undefined
    },
    subGroup: {
      type: Boolean,
      default: () => !0
    }
  },
  data () {
    return {}
  },
  computed: {
    getActiveList () {
      const { items } = this.item || []
      // return
      // console.log(Boolean(this.$helpers.findBy(items, this.$route.name, 'name')))
      return this.$helpers.findBy(items, this.$route.name, 'name')
    },
    activeClasses () {
      return [
        'active-item',
        'accent--text'
        // this.themeLight ? 'darken-3' : 'lighten-3'
        // this.themeDark ? 'white--text' : 'black--text'
      ].join(' ')
    }
  },
  methods: {
    itemClick (item) {
      // item.badge = 0
      // console.log(item)
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
.v-list-item.active-item {
  //color: var(--v-anchor-base) !important;
}

</style>
