<template>
  <v-card
    v-if="src"
    :href="src"
    :width="getWidth"
    class="pa-1 mx-auto my-1"
    color="grey"
    elevation="3"
    target="_blank"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-img
      :src="src"
      :width="getWidth"
      class="my-2 mx-auto"
      contain
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'DtAvatar',
  props: ['src'],
  computed: {
    getWidth () {
      return this.$attrs.width || 100
    }
  }
}
</script>
