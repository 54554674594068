<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->
<template>
  <component :is="tag">
    <app-auto-select
      :items="brands"
      :value="value"
      v-bind="$attrs"
      @input="inputBrand($event)"
      v-on="$listeners"
    />
    <app-auto-select
      :value="brandItemId"
      name="brand_item_id"
      :items="brandItems"
      :label="parseAttribute('brand_item_id')"
      :vid="`quotation_items.${index}.brand_item_id`"
      @input="inputBrandItem"
      required
    />
  </component>
</template>
<script>

export default {
  name: 'BrandsContainer',
  props: {
    value: {
      type: [String, Number],
      default: () => undefined
    },
    brandItemId: {
      type: [String, Number],
      default: () => undefined
    },
    index: {
      type: [String, Number],
      default: () => undefined
    },
    tag: {
      type: String,
      default: () => 'span'
    },
    brands: {
      type: Array,
      default: () => ([])
    },
    brand: {
      type: [String, Number],
      default: () => null
    },
    brandItem: {
      type: [String, Number],
      default: () => null
    }
  },
  data () {
    return {
      brandsList: [],
      brandItems: [],
      brandModel: undefined,
      brandItemModel: undefined
    }
  },
  mounted () {
    this.brandModel = this.value
    this.brandModel && this.inputBrand(this.brandModel,this.brandItemId)
  },
  methods: {
    inputBrand (brand, brandItemId) {
      // console.log(brand, remove)
      const b = this.brands.find(e => e.id === brand)
      this.brandItems = b?.brand_items || []
      // brandItemId !== undefined && (this.brandItemModel = brandItemId)
      // brandItemId !== undefined && (this.$emit('update:brandItemId', brandItemId))
    },
    inputBrandItem (brandItem) {
      // console.log(brandItem)
      this.$emit('input:item', brandItem)
    }
  }
}
</script>
