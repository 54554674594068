/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  props: {
    mode: {
      // default: () => 'aggressive'
      default: () => 'lazy'
      // default: () => undefined
    },
    vid: {},
    value: {},
    name: {
      required: true
    },
    hideTopLabel: {
      type: Boolean,
      default: () => !0
    },
    label: {
      default () {
        return undefined
      }
    },
    placeholder: {
      default () {
        return this.label
      }
    },
    rules: {
      type: [Array, String],
      default: () => undefined
    },
    errors: {
      type: Array,
      default: () => []
    },
    errorCount: {
      type: [Number, String],
      default: () => undefined
    },
    clearable: {
      type: Boolean,
      default: () => !1
    },
    readonly: {
      type: Boolean,
      default: () => !1
    },
    disabled: {
      type: Boolean,
      default: () => !1
    },
    required: {
      type: Boolean,
      default: () => !1
    },
    immediate: {
      default: () => false
    },
    counter: {
      type: Boolean,
      default: () => false
    },
    forceClearable: {
      type: Boolean,
      default: () => !1
    },
    hideRequired: {
      type: Boolean,
      default: () => !1
    }
  },
  computed: {
    getAutocomplete () {
      return this.$attrs?.autocomplete || this.name
    },
    getLabel () {
      const k = this.label === undefined ? this.name : this.label

      let label = ''
      if (k) {
        label = this.parseAttribute(k)
        if (label && this.hasRequired && !this.hideRequired) {
          label = `${label} *`
        }
      }
      return label
    },
    getTopLabel () {
      const label = this.getLabel
      let html = label ? label.trim() : ''
      if (label && label.substr(-1, 1).trim() === '*') {
        html = html.substr(0, html.length - 1)
        html += '<span class="error--text">*</span>'
      }
      return html
    },
    getPlaceholder () {
      if (!this.placeholder && this.hasMobile) {
        return '05xxxxxxxx'
      }
      const k = this.placeholder === undefined ? this.name : this.placeholder
      return k ? this.parseAttribute(k) : ''
    },
    getRules () {
      let rules = this.rules || []
      rules = typeof rules === 'string' ? rules.split('|') : rules
      if (this.required === true && rules.indexOf('required') === -1) {
        rules.push('required')
      }
      // console.log(rules)
      return rules.join('|')
      // return 'required';
    },
    getClearable () {
      return this.forceClearable === true ? true : (this.readonly === true ? false : this.clearable)
      // return this.readonly === true || this.disabled === true ? false : this.clearable
    },
    hasRequired () {
      if (this.required === true) return true
      let r = this.getRules || ''
      if (typeof r === 'string') {
        r = r.split('|')
      }

      return r.indexOf('required') >= 0
    },
    hasMobile () {
      let r = this.getRules || ''
      if (typeof r === 'string') {
        r = r.split('|')
      }

      return r.indexOf('mobile') >= 0
    },
    getErrorCount () {
      return this.errorCount === undefined ? 1 : (parseInt(this.errorCount) || 1)
    },
    getErrorMessages () {
      const provider = this.$refs.provider
      let errors = [...this.errors]
      provider && (errors = [...errors, ...provider.errors])

      return errors
    },
    isEnglishName () {
      const name = (this.name || '').toLowerCase()
      // console.log(name.slice(-3, -2), name, ['_id', '_ar'].indexOf(name.slice(-3)))
      return name.slice(-3, -2) === '_' && name.slice(-3) !== '_ar' && ['_id', '_ar'].indexOf(name.slice(-3)) === -1
    }
  }
}
