<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-dialog-loading.sync="formDialogLoading"
      :form-dialog.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
    >
      <template #filter="{datatableFilters}">
        <v-row>
          <app-all-role-codes
            v-model="datatableFilters.role_id"
            clearable
            cols="12"
            hide-details
            md="6"
            multiple
            name="role_id"
          />
          <app-branches
            v-model="datatableFilters.branch_id"
            clearable
            cols="12"
            hide-details
            name="branch_id"
            sm="6"
          />
          <app-job-titles
            v-model="datatableFilters.job_title_id"
            clearable
            cols="12"
            hide-details
            name="job_title_id"
            sm="6"
          />
        </v-row>
        <v-row>
          <app-date-picker
            v-model="datatableFilters.from_created_at"
            cols="12"
            hide-details
            md="6"
            name="from_created_at"
          />
          <app-date-picker
            v-model="datatableFilters.to_created_at"
            cols="12"
            hide-details
            md="6"
            name="to_created_at"
          />
        </v-row>
        <v-row>
          <app-filter-select
            v-model="datatableFilters.active"
            clearable
            cols="12"
            md="6"
            name="active"
          />
        </v-row>
      </template>

      <template #formDialog="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-container fluid>
            <account-form
              v-model="selectedItem"
            >
              <template #before>
                <v-row
                  v-if="isOldItem && selectedItem.signature && userHasPermission('destroySignature')"
                  justify="center"
                  justify-sm="start"
                >
                  <v-col cols="auto">
                    <v-row
                      align="center"
                      dense
                      justify="space-between"
                    >
                      <v-col cols="auto">
                        <span class="text-h5">{{ parseAttribute('signature') }}</span>
                      </v-col>
                      <v-col cols="auto">
                        <app-btn
                          :disabled="loading"
                          color="error"
                          icon
                          @click="destroySignature"
                        >
                          <v-icon>cancel</v-icon>
                        </app-btn>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="auto">
                        <v-card color="grey">
                          <v-img
                            :lazy-src="AppLogo"
                            width="200"
                            :src="selectedItem.signature"
                            max-height="100%"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <app-all-role-codes
                    ref="roles"
                    v-model="selectedItem.roles_id"
                    cols="12"
                    multiple
                    name="roles_id"
                    required
                  />
                </v-row>
              </template>
              <template #after>
                <personal-form
                  v-model="selectedItem"
                  show-account-type
                />
                <password-form v-model="selectedItem" />
                <v-row>
                  <v-col cols="auto">
                    <app-btn
                      :block="AppIsSmall"
                      :loading="formDialogLoading"
                      @click="submitClickForm"
                    >
                      {{ $t('save') }}
                    </app-btn>
                  </v-col>
                </v-row>
              </template>
            </account-form>
          </v-container>
        </app-form>
      </template>

      <!--<template v-slot:item.name="{item,on}">
        <app-datatable-edit-dialog
            v-model="item.name"
            v-on="on"
            :item.sync="item"
            :update-method="updateOneItemData"
            name="name"
        />
      </template>

      <template v-slot:item.mobile="{item,on}">
        <app-datatable-edit-dialog
            v-model="item.mobile"
            v-on="on"
            :item.sync="item"
            :update-method="updateOneItemData"
            name="mobile"
            type="number"
        />
      </template>

      <template v-slot:item.email="{item,on}">
        <app-datatable-edit-dialog
            v-model="item.email"
            v-on="on"
            :item.sync="item"
            :update-method="updateOneItemData"
            name="email"
        />
      </template>
      <template v-slot:item.balance="{item,on}">
        <app-datatable-edit-dialog
          v-model="item.balance"
          :item.sync="item"
          :update-method="updateUserBalance"
          name="balance"
          v-on="on"
        />
      </template>
      -->

      <template #item.control="{item,on,index}">
        <app-dt-btn
          v-if="userHasPermission('update')"
          :disabled="loadingDatatable"
          :loading="loadingDatatable"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          :disabled="loadingDatatable"
          :loading="loadingDatatable"
          destroy
          @click="deleteItem(item)"
        />
        <app-dt-btn
          v-if="item.active===!0 && userHasPermission('inactive')"
          :disabled="loadingDatatable"
          :loading="controlLoading"
          tooltip="inactive_account"
          @click="inactiveUser(item,index)"
        >
          <v-icon color="error">
            block
          </v-icon>
        </app-dt-btn>
        <app-dt-btn
          v-if="item.active === !1 && userHasPermission('active')"
          :disabled="loadingDatatable"
          :loading="controlLoading"
          tooltip="active_account"
          @click="activeUser(item,index)"
        >
          <v-icon color="success">
            check
          </v-icon>
        </app-dt-btn>
      </template>
    </app-datatable>
  </app-card>
</template>
<script>
import { MetaInfoMixin, DatatableMixin, UserStatusMixin } from '@mixins'
import AccountForm from '@components/user/AccountForm'
import PasswordForm from '@components/user/PasswordForm'
import PersonalForm from '@components/user/PersonalForm'

export default {
  name: 'Index',
  components: { PersonalForm, PasswordForm, AccountForm },
  helperApiName: 'user',
  mixins: [MetaInfoMixin, DatatableMixin, UserStatusMixin],
  data () {
    return {
      modelPermission: 'User',
      loading: !1,
      headers: [
        'username',
        'name',
        'email',
        'mobile',
        'branch_id_to_string',
        'roles_id_to_string',
        'job_title_id_to_string',
        'created_at_to_string',
        {
          text: 'status',
          value: 'active_to_string'
        },
        'control'
      ]
    }
  },
  computed: {
    defaultSelectedItem () {
      return {
        active: !0
      }
    }
  },
  mounted () {
    this.checkPermission('index')
  },
  methods: {
    destroySignature () {
      if (this.loading) return
      this.confirmMessage(() => {
        this.loading = !0
        this.apiService.user.destroySignature(this.selectedItem.id).then(({ _data, _message }) => {
          _message && this.alertSuccess(_message)
          _data && (this.updateSelectedItem(_data))
          if (_data?.id === this.authUser.id) {
            this.authUser = { ...this.authUser, signature: undefined }
          }
        }).catch(e => {
          const message = e?._message || e?.message
          message && this.alertError(message)
        }).finally(() => (this.loading = !1))
      })
    }
  }
}
</script>
