<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-col-span v-bind="{...$attrs,cols}">
    <ValidationProvider
      v-slot="v"
      :name="name"
      :rules="rules"
      :vid="vid"
    >
      <slot name="top" />
      <v-switch
        v-model.number="val"
        :error-count="v.errors.length"
        :error-messages="v.errors"
        :label="getLabel"
        class="mx-2"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <slot />
      <slot name="bottom" />
    </ValidationProvider>
  </app-col-span>
</template>

<script>
export default {
  name: 'Switcher',
  props: {
    value: {},
    vid: {},
    name: {
      required: true
    },
    rules: {
      default: () => ''
    },
    label: {
      type: String,
      // default: () => 'is_active'
      default: () => undefined
    },
    statusNames: {
      type: Array,
      default: () => (['active', 'status'])
    },
    defaultValue: {
      default: () => undefined
    },
    cols: {
      default: () => 'auto'
    }
  },
  computed: {
    getLabel () {
      return this.parseAttribute(this.label || this.name)
    },
    val: {
      get () {
        return this.value === undefined ? this.defaultValue : this.value
        // return (this.value === undefined ? (this.statusNames.indexOf(this.name) === -1 ? this.defaultValue : !0) : this.value);
      },
      set (v) {
        // console.log(2);
        this.$emit('input', v)
      }
    }
  }
}
</script>
