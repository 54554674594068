/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import camelCase from 'lodash/camelCase'

const modules = {}
const requireModule = require.context('./modules', false, /\.js$/)

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))
  const m = requireModule(fileName)
  modules[moduleName] = m.default || m
})

export default modules
