<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<script>

import AxiosSelect from '@components/base/inputs/AxiosSelect'

export default {
  name: 'Currencies',
  extends: AxiosSelect,
  computed: {
    getAxiosUrl () {
      return this.apiService.currency.staticUtilities
    }
  }
}
</script>
