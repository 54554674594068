/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  computed: {},
  methods: {
    fetchNotifications () {
      if (this.FetchingAppNotifications || !this.authUser) return
      this.FetchingAppNotifications = !0

      return this.apiService.notification.index({ lastNotifications: 1 }).then(({ _data: data, _success: success }) => {
        if (success && data) {
          this.FetchedAppNotifications = !0
          this.AppNotifications = data.notifications
          this.UnreadAppNotifications = data.unreadCount
        }
      }).finally(() => (this.FetchingAppNotifications = !1))
    },
    initAppNotifications () {
      if (this.FetchedAppNotifications) {
        return
      }

      this.fetchNotifications()
      // this.AppNotificationsInterval = setInterval(this.fetchNotifications, (10 * 1000))
    },
    clearAppNotifications () {
      if (this.AppNotificationsInterval) {
        clearInterval(this.AppNotificationsInterval)
      }
      this.AppNotifications = []
      this.UnreadAppNotifications = 0
      this.FetchedAppNotifications = !1
      this.FetchingAppNotifications = !1
    }
  }
}
