<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-container
    v-bind="$attrs"
    v-on="$attrs"
  >
    <v-row dense>
      <v-col cols="12">
        <v-card
          :elevation="elevation"
          tile
          v-bind="$attrs"
          v-on="$attrs"
        >
          <template v-if="getCardTitle">
            <v-card-title>
              <slot name="beforeTitle" />
              {{ getCardTitle }}
              <slot name="afterTitle" />
            </v-card-title>
            <v-divider class="primary" />
          </template>
          <slot />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Card',
  props: {
    elevation: {
      type: [String, Number],
      default: () => 4
    },
    title: {
      type: String,
      default: () => undefined
    }
  },
  computed: {
    getCardTitle () {
      if (this.title !== undefined) return this.title

      const items = this.AppSideMenu
      if (!items) return this.title

      const title = this.getTitleFormItems(items)
      return title || this.title || this.getPageTitle()
    }
  },
  methods: {
    getTitleFormItems (items) {
      const routeName = this.$route.name
      let index
      let item
      for (index in items) {
        item = items[index]
        if (item.name === routeName && item.title) {
          return item.title
        }
        if (item.items) {
          const title = this.getTitleFormItems(item.items)
          if (title) return title
        }
      }
    }
  }
}
</script>
