<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card fluid>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-dialog-loading.sync="formDialogLoading"
      :form-dialog.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :on-click-modal-activator="onClickModalActivator"
      :url="tableUrl"
      control-width="250px"
      excel
    >
      <template #filter="{datatableFilters}">
        <v-row>
          <app-users
            v-model="datatableFilters.user_id"
            clearable
            cols="12"
            hide-details
            name="user_id"
            sm="6"
          />
          <app-branches
            v-model="datatableFilters.branch_id"
            clearable
            cols="12"
            hide-details
            name="branch_id"
            sm="6"
          />
          <app-text-input
            v-model="datatableFilters.organization_name"
            clearable
            cols="12"
            hide-details
            name="organization_name"
            sm="6"
          />
          <app-text-input
            v-model="datatableFilters.customer_name"
            clearable
            cols="12"
            hide-details
            name="customer_name"
            sm="6"
          />
          <app-text-input
            v-model="datatableFilters.mobile"
            clearable
            cols="12"
            hide-details
            name="mobile"
            sm="6"
          />
        </v-row>
        <v-row>
          <app-date-picker
            v-model="datatableFilters.from_date"
            cols="12"
            hide-details
            name="from_date"
            sm="6"
          />
          <app-date-picker
            v-model="datatableFilters.to_date"
            cols="12"
            hide-details
            name="to_date"
            sm="6"
          />
        </v-row>
        <v-row>
          <app-date-picker
            v-model="datatableFilters.from_created_at"
            cols="12"
            hide-details
            name="from_created_at"
            sm="6"
          />
          <app-date-picker
            v-model="datatableFilters.to_created_at"
            cols="12"
            hide-details
            name="to_created_at"
            sm="6"
          />
        </v-row>
      </template>

      <template #formDialog="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-row>
            <template
              v-if="isAdminUser"
            >
              <app-users
                v-model="selectedItem.user_id"
                cols="12"
                name="user_id"
                required
                sm="6"
              />
              <app-branches
                v-model="selectedItem.branch_id"
                clearable
                cols="12"
                name="branch_id"
                sm="6"
              />
            </template>
            <app-date-picker
              v-model="selectedItem.date"
              cols="12"
              name="date"
              required
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.organization_name"
              cols="12"
              name="organization_name"
              required
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.customer_name"
              clearable
              cols="12"
              name="customer_name"
              sm="6"
            />
            <app-mobile-input
              v-model="selectedItem.mobile"
              cols="12"
              name="mobile"
              required
              sm="6"
            />
            <!--<app-auto-select-->
            <!--  v-model="selectedItem.expire_days"-->
            <!--  :items="expireDays"-->
            <!--  required-->
            <!--  cols="12"-->
            <!--  name="expire_days"-->
            <!--  sm="6"-->
            <!--/>-->
            <app-number-input
              v-model="selectedItem.expire_days"
              :suffix="parseAttribute('day')"
              cols="12"
              name="expire_days"
              required
              sm="6"
            />
            <app-supplying-durations
              v-model="selectedItem.supplying_duration_id"
              cols="12"
              name="supplying_duration_id"
              required
              sm="6"
            />
            <app-float-input
              v-model="selectedItem.vat"
              cols="12"
              name="vat"
              required
              sm="6"
              suffix="%"
            />
          </v-row>
          <v-row dense>
            <app-textarea-input
              v-model="selectedItem.notes"
              clearable
              cols="12"
              name="notes"
            />
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
            >
              <v-container
                fluid
              >
                <v-row dense>
                  <v-col cols="12">
                    <div class="overflow-x-auto">
                      <v-data-table
                        v-if="dialog"
                        ref="quotationItems"
                        :headers="quotationItemsHeaders"
                        :items="selectedItem.quotation_items"
                        class="quotation-items"
                        dense
                        disable-filtering
                        disable-pagination
                        disable-sort
                        fixed-header
                        hide-default-footer
                      >
                        <template #item.control="{index}">
                          <v-btn
                            v-show="index > 0"
                            color="error"
                            icon
                            @click="removeQuotationItem(index)"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </template>

                        <template #item.brand_id="{index}">
                          <brands-container
                            v-if="dialog"
                            v-model="selectedItem.quotation_items[index].brand_id"
                            :brand-item-id="selectedItem.quotation_items[index].brand_item_id"
                            :brands="brands"
                            :index="index"
                            :items="brands"
                            :vid="`quotation_items.${index}.brand_id`"
                            :with-col="false"
                            name="brand_id"
                            required
                            @input:item="updateBrandItem($event,index)"
                          />
                        </template>

                        <template #item.vin="{index}">
                          <app-text-input
                            v-model="selectedItem.quotation_items[index].vin"
                            :vid="`quotation_items.${index}.vin`"
                            :with-col="false"
                            name="vin"
                          />
                        </template>

                        <template #item.specifications="{index}">
                          <app-textarea-input
                            v-model="selectedItem.quotation_items[index].specifications"
                            :vid="`quotation_items.${index}.specifications`"
                            :with-col="false"
                            class="w-100 d-block"
                            name="specifications"
                            clearable
                          />
                        </template>

                        <template #item.exterior_color="{index}">
                          <app-combobox
                            v-model="selectedItem.quotation_items[index].exterior_color"
                            :items="colors"
                            :vid="`quotation_items.${index}.exterior_color`"
                            :with-col="false"
                            name="exterior_color"
                          />
                        </template>

                        <template #item.interior_color="{index}">
                          <app-combobox
                            v-model="selectedItem.quotation_items[index].interior_color"
                            :items="colors"
                            :vid="`quotation_items.${index}.interior_color`"
                            :with-col="false"
                            name="interior_color"
                          />
                        </template>

                        <template #item.model="{index}">
                          <app-auto-select
                            v-model="selectedItem.quotation_items[index].model"
                            :items="getModels"
                            :vid="`quotation_items.${index}.model`"
                            :with-col="false"
                            name="model"
                            required
                          />
                        </template>

                        <template #item.quantity="{index}">
                          <app-number-input
                            v-model="selectedItem.quotation_items[index].quantity"
                            :vid="`quotation_items.${index}.quantity`"
                            :with-col="false"
                            name="quantity"
                            required
                          />
                        </template>

                        <template #item.price="{index}">
                          <app-price-input
                            v-model="selectedItem.quotation_items[index].price"
                            :suffix="parseAttribute('sar')"
                            :vid="`quotation_items.${index}.price`"
                            :with-col="false"
                            name="price"
                            required
                          />
                        </template>

                        <template #item.plates="{index}">
                          <app-price-input
                            v-model="selectedItem.quotation_items[index].plates"
                            :suffix="parseAttribute('sar')"
                            :vid="`quotation_items.${index}.plates`"
                            :with-col="false"
                            name="plates"
                          />
                        </template>

                        <template #item.insurance="{index}">
                          <app-price-input
                            v-model="selectedItem.quotation_items[index].insurance"
                            :suffix="parseAttribute('sar')"
                            :vid="`quotation_items.${index}.insurance`"
                            :with-col="false"
                            name="insurance"
                          />
                        </template>

                        <!--<template #item.vat="{item}">
                          {{ (parseFloat(item.price) * (AppSettings.vat || 0) /100 ).toFixed(2) }}
                        </template>-->

                        <!--<template #item.total="{item}">
                          {{ ((parseFloat(item.price) * (AppSettings.vat || 0) /100) + parseFloat(item.insurance)+ parseFloat(item.plates)).toFixed(2) }}
                        </template>-->
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              cols="12"
            >
              <v-toolbar>
                <v-toolbar-title>{{ $t('quotationPage.add_row') }}</v-toolbar-title>
                <v-spacer />
                <!--<v-btn-->
                <!--  class="mx-2"-->
                <!--  color="primary"-->
                <!--  @click="itemsDialog.open"-->
                <!--&gt;-->
                <!--  {{ parseAttribute('import') }}-->
                <!--</v-btn>-->
                <v-btn
                  class="mx-2"
                  color="accent"
                  @click="addQuotationItem"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-btn
                :block="AppIsSmall"
                :loading="formDialogLoading"
                @click="submitClickForm"
              >
                {{ $t('save') }}
              </app-btn>
            </v-col>
          </v-row>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('show') && item.items_specifications && item.items_specifications.length > 0"
          color="success"
          @click="copyUrl(item)"
        >
          {{ $t('copy_specifications') }}
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('show') && item.items_specifications && item.items_specifications.length > 0"
          color="success"
          @click="copyUrl(item,!0)"
        >
          {{ $t('copy_specifications_with_name') }}
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('show') && item.print_url"
          print
          @click="printItem(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('show') && item.print_url_normal"
          @click="printItem(item,!0)"
        >
          {{ $t('normal_print') }}
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('show') && item.init_invoice_url"
          @click="printInitInvoice(item)"
        >
          {{ $t('init_invoice') }}
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('update')"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
    <v-dialog
      v-model="itemsDialog.value"
      max-width="580"
      no-click-animation
      persistent
    >
      <v-card>
        <v-card-title>{{ parseAttribute('import') }} {{ parseAttribute('quotation_items') }}</v-card-title>
        <v-card-text>
          <app-form
            ref="formItems"
            :errors="formErrors"
            :submit="importItems"
          >
            <v-container>
              <v-row dense>
                <v-spacer />
                <v-col cols="auto">
                  <app-btn
                    :disabled="loading"
                    color="blue"
                    @click="downloadImportItemsExample"
                  >
                    <v-icon left>
                      download
                    </v-icon>
                    {{ $t('quotationPage.download_import_items_example') }}
                  </app-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <app-file-input
                  v-model="itemsDialog.attachment"
                  cols="12"
                  name="attachment"
                  required
                />
              </v-row>
              <v-row
                dense
                justify="space-between"
              >
                <v-col cols="auto">
                  <app-submit :loading="loading">
                    {{ parseAttribute('import') }}
                  </app-submit>
                </v-col>
                <v-col cols="auto">
                  <app-btn
                    :disabled="loading"
                    color="error"
                    @click="itemsDialog.close"
                  >
                    {{ parseAttribute('close') }}
                  </app-btn>
                </v-col>
              </v-row>
            </v-container>
          </app-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </app-card>
</template>
<script>
import { DatatableMixin, GetHeadersMixin, MetaInfoMixin } from '@mixins'
import BrandsContainer from '@components/base/resources/brand/BrandsContainer'
import { APP_ROUTES } from '@routes/list'

export default {
  name: 'Index',
  components: { BrandsContainer },
  helperApiName: 'quotation',
  mixins: [MetaInfoMixin, DatatableMixin, GetHeadersMixin],
  data () {
    return {
      modelPermission: 'Quotation',
      headers: [
        'quotation_id',
        'user_id_to_string',
        'branch_id_to_string',
        'organization_name',
        'customer_name',
        'mobile',
        'date_to_string',
        'expire_days_to_string',
        'supplying_duration_id_to_string',
        'total_to_string',
        'vat_to_string',
        'created_at_to_string',
        'control'
      ],
      loading: !1,
      brands: [],
      brandItems: [],
      colors: [],
      itemsDialog: {
        value: !1,
        attachment: undefined,
        open: () => {
          this.itemsDialog = { ...this.itemsDialog, value: !0, attachment: undefined }
        },
        close: () => {
          this.itemsDialog = { ...this.itemsDialog, value: !1, attachment: undefined }
        }
      }
    }
  },
  computed: {
    quotationItemsHeaders () {
      const h = [
        'control',
        {
          text: '',
          value: 'brand_id'
        },
        {
          text: '',
          value: 'vin'
        },
        {
          text: '',
          value: 'specifications',
          width: this.AppIsSmall ? '100%' : 450
        },
        {
          text: '',
          value: 'exterior_color'
        },
        {
          text: '',
          value: 'interior_color'
        },
        {
          text: '',
          value: 'model',
          width: this.AppIsSmall ? '100%' : 130
        },
        {
          text: '',
          value: 'quantity',
          width: this.AppIsSmall ? '100%' : 100
        },
        {
          text: '',
          value: 'price'
        },
        {
          text: '',
          value: 'plates'
        },
        {
          text: '',
          value: 'insurance'
        }
      ]
      return this.parseHeaders(h).map(e => ({ ...e, width: e.width || (e.value === 'control' ? undefined : (this.AppIsSmall ? '100%' : 200)) }))
    },
    defaultSelectedItem () {
      // console.log(2)
      return {
        // brand_id: null,
        order_by: 0,
        active: !0,
        quotation_items: [],
        vat: this.AppSettings.tax
      }
    },
    getModels () {
      const v = []
      let i = 2010
      for (; i <= 2040; ++i) {
        v.push(i)
      }
      return v
    }
  },
  mounted () {
    this.checkPermission('index')
    this.$nextTick(() => {
      this.iniQuotationItemUtilities()
    })
  },
  methods: {
    downloadImportItemsExample () {
      this.openWindow(this.apiService.utilities.downloadImportItemsExample)
    },
    importItems () {
      if (this.loading || !this.itemsDialog.attachment) return
      this.confirmMessage(this.$t('messages.import_quotation_items_confirm'), async () => {
        this.loading = !0
        const form = new FormData()
        this.$helpers.appendArray(form, { attachment: this.itemsDialog.attachment })
        // console.log(this.itemsDialog.attachment)
        this.apiService.quotation.importItems(form).then(({ _data }) => {
          // console.log(_data)
        }).catch((e) => {
          const message = e?._message || e?.message
          message && this.alertError(message)
        }).finally(() => (this.loading = !1))
      })
    },
    async iniQuotationItemUtilities () {
      if (this.loading) return
      this.loading = !0
      this.apiService.utilities.quotationItemUtilities().then(({ _data }) => {
        this.brands = _data?.brands || []
        this.brandItems = _data?.brand_items || []
        // console.log(_data)
        this.colors = _data?.colors || []
      }).catch(e => e).finally(() => (this.loading = !1))
    },
    getDefaultQuotationItem () {
      return {
        brand_id: undefined,
        brand_item_id: undefined,
        specifications: undefined,
        exterior_color: undefined,
        interior_color: undefined,
        model: undefined,
        quantity: 1,
        price: undefined,
        plates: 0,
        insurance: 0
      }
    },
    addQuotationItem () {
      this.selectedItem = {
        ...this.selectedItem,
        quotation_items: [
          ...this.selectedItem.quotation_items,
          { ...this.getDefaultQuotationItem() }
        ]
      }
      this.$nextTick(() => {
        if (this.$refs.quotationItems?.$el?.firstChild) {
          this.$refs.quotationItems.$el.firstChild.scrollLeft = 0
        }
      })
    },
    removeQuotationItem (index) {
      if (this.selectedItem.quotation_items.length <= 1) return

      this.selectedItem = {
        ...this.selectedItem,
        quotation_items: this.selectedItem.quotation_items.filter((e, i) => i !== index)
      }
    },
    printItem (item, normal = !1) {
      this.openWindow(normal ? item.print_url_normal : item.print_url)
    },
    printInitInvoice (item) {
      this.openWindow(item.init_invoice_url)
    },
    updateBrandItem (item, index) {
      if (!item?.id) {
        const brand = this.selectedItem.quotation_items[index]?.brand_id
        item = this.brands.find(b => b.id === brand)?.brand_items?.find(e => e.id === item)
      }

      const update = (replace = !1) => {
        const selectedItem = { ...this.selectedItem }
        const items = [...selectedItem.quotation_items]
        let specifications = ''

        if (replace) {
          // specifications = (item?.name || '')
          specifications += (specifications ? '\n' : '') + (item?.specifications || '')
        } else {
          specifications += (items[index]?.specifications || '')
        }

        if (!specifications) {
          // specifications = item?.name || ''
          specifications += (specifications ? '\n' : '') + (item?.specifications || '')
        }
        items[index] = {
          ...items[index],
          specifications,
          brand_item_id: item.id
        }

        this.updateSelectedItem({ ...selectedItem, quotation_items: [...items] })
        // this.selectedItem.quotation_items = [...items]
      }

      if (item?.specifications && this.selectedItem.quotation_items[index].specifications && item?.specifications !== this.selectedItem.quotation_items[index].specifications) {
        this.confirmMessage(this.$t('messages.specifications_update'), () => {
          update(!0)
        })
      } else {
        update()
      }
    },
    transformFormDatatable (form) {
      const keys = ['exterior_color', 'interior_color']
      form.quotation_items.map(e => {
        keys.forEach(c => {
          if (typeof e[c] === 'object') {
            e[c] = e[c]?.text
          }
        })
        return e
      })
      return form
    },
    onClickModalActivator () {
      setTimeout(() => {
        this.addQuotationItem()
      }, 90)
    },
    copyUrl (quotation, withName = !1) {
      const { items_specifications } = quotation || {}
      if (items_specifications?.length > 0) {
        let text = ''
        let item
        for (const i in items_specifications) {
          item = items_specifications[i]
          const url = `${window.location.protocol}//${window.location.host}${this.$router.resolve({
            name: APP_ROUTES.public.viewSpecification,
            params: { id: item.id }
          }).href}`
          text += (text ? '\n' : '')
          if (item?.brand_item?.name && withName) {
            text += `${item.brand_item.name}${item.model ? ` ${item.model}` : ''}\n`
          }
          text += url
        }
        this.copyText(text)
        this.alertSuccess(this.$t('specificationPage.link_copied'))
      }
    }
  }
}
</script>

<style lang="scss">

.quotation-items {

  td:not(.v-data-table__mobile-row) {
    vertical-align: top !important;
  }

  .v-data-table__mobile-row,
  .v-data-table__mobile-row__cell {
    width: 100% !important;
  }

  .v-data-table__wrapper .v-data-table__mobile-row {
    width: 100% !important;
  }

  .v-data-table__wrapper .v-data-table__mobile-row > .v-data-table__mobile-row__header {
    display: none;
  }
}

</style>
