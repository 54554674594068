<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<script>
import NumberInput from './NumberInput'

export default {
  name: 'MobileInput',
  components: { NumberInput },
  extends: NumberInput
}
</script>
