<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-text-input
    :append-icon="iconName"
    :type="inputType"
    v-bind="$attrs"
    v-on="$listeners"
    @click:append="toggle"
  >
    <template #top>
      <slot name="top" />
    </template>
    <template #default>
      <slot />
    </template>
    <template #bottom>
      <slot name="bottom" />
    </template>
  </app-text-input>
</template>

<script>

export default {
  name: 'PasswordInput',
  props: {
    hideIcon: {
      type: Boolean,
      default: () => !1
    }
  },
  data: () => ({
    show: !1
  }),
  computed: {
    inputType () {
      return this.show ? 'text' : 'password'
    },
    iconName () {
      if (this.hideIcon) return undefined
      return this.show ? 'visibility_off' : 'visibility'
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    }
  }
}
</script>

<style scoped>

</style>
