<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-dialog-loading.sync="formDialogLoading"
      :form-dialog.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
    >
      <template #formDialog="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <app-row>
            <app-text-input
              v-model="selectedItem.name_ar"
              cols="12"
              name="name_ar"
              required
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.name_en"
              cols="12"
              name="name_en"
              required
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.mobile"
              clearable
              cols="12"
              name="mobile"
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.email"
              clearable
              cols="12"
              name="email"
              sm="6"
            />
            <!--<app-textarea-input-->
            <!--  v-model="selectedItem.address"-->
            <!--  clearable-->
            <!--  cols="12"-->
            <!--  name="address"-->
            <!--/>-->
          </app-row>
          <app-row>
            <v-col>
              <app-btn
                :block="AppIsSmall"
                :loading="formDialogLoading"
                @click="submitClickForm"
              >
                {{ $t('save') }}
              </app-btn>
            </v-col>
          </app-row>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('update')"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
  </app-card>
</template>
<script>
import { DatatableMixin, MetaInfoMixin } from '@mixins'

export default {
  name: 'Index',
  helperApiName: 'customer',
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      modelPermission: 'Customer',
      headers: [
        'name',
        'mobile',
        'email',
        // 'address',
        'control'
      ]
    }
  },
  mounted () {
    this.checkPermission('index')
  }
}
</script>
