<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-dialog-loading.sync="formDialogLoading"
      :form-dialog.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
    >
      <template #formDialog="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <app-row>
            <app-text-input
              v-model="selectedItem.name_ar"
              cols="12"
              name="name_ar"
              required
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.name_en"
              cols="12"
              name="name_en"
              required
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.decimal_name_ar"
              cols="12"
              name="decimal_name_ar"
              required
              sm="6"
            />
            <app-text-input
              v-model="selectedItem.decimal_name_en"
              cols="12"
              name="decimal_name_en"
              required
              sm="6"
            />
            <!--<app-text-input-->
            <!--  v-model="selectedItem.symbol"-->
            <!--  cols="12"-->
            <!--  name="symbol"-->
            <!--  required-->
            <!--  sm="6"-->
            <!--/>-->
            <app-text-input
              v-model="selectedItem.code"
              cols="12"
              name="code"
              required
              sm="6"
            />
            <!--<app-number-input-->
            <!--  v-model="selectedItem.rate"-->
            <!--  cols="12"-->
            <!--  name="rate"-->
            <!--  sm="6"-->
            <!--/>-->
            <!--<app-number-input-->
            <!--  v-model="selectedItem.order_by"-->
            <!--  cols="12"-->
            <!--  name="order_by"-->
            <!--  sm="6"-->
            <!--/>-->
          </app-row>
          <!--<app-row>-->
          <!--  <app-switcher-->
          <!--    v-model="selectedItem.active"-->
          <!--    cols="auto"-->
          <!--    name="active"-->
          <!--  />-->
          <!--</app-row>-->
          <app-row>
            <v-col>
              <app-btn
                :block="AppIsSmall"
                :loading="formDialogLoading"
                @click="submitClickForm"
              >
                {{ $t('save') }}
              </app-btn>
            </v-col>
          </app-row>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('update')"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
  </app-card>
</template>
<script>
import { MetaInfoMixin, DatatableMixin } from '@mixins'

export default {
  name: 'Currencies',
  helperApiName: 'currency',
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      modelPermission: 'Utilities.Currency',
      headers: [
        'name',
        'decimal_name',
        // 'symbol',
        // 'rate',
        'code',
        // {
        //   text: 'status',
        //   value: 'active_to_string'
        // },
        // 'order_by',
        'control'
      ]
    }
  },
  computed: {
    defaultSelectedItem () {
      return {
        rate: 1,
        order_by: 0,
        active: !0
      }
    }
  },
  mounted () {
    this.checkPermission('index')
  }
}
</script>
