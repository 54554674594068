<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="false"
      :headers="headers"
      :item-class="itemClass"
      :search="false"
      :url="tableUrl"
      disable-sort
      hide-default-header
      hide-top-slot
      @click:row="itemClick"
    />

    <dialog-modal
      v-model="notificationDialogValue"
      :fullscreen="AppIsSmall"
      max-width="500"
      no-click-animation
      persistent
    >
      <notification-view
        v-if="notificationDialogItem"
        :notification="notificationDialogItem"
        @close="closeNotificationDialog"
      />
    </dialog-modal>
  </app-card>
</template>
<script>
import { MetaInfoMixin, DatatableMixin, NotificationDialogMixin } from '@mixins'
import NotificationView from '@components/base/app/NotificationView'
import DialogModal from '@components/base/grid/DialogModal'

export default {
  name: 'Index',
  helperApiName: 'notification',
  components: { DialogModal, NotificationView },
  mixins: [MetaInfoMixin, DatatableMixin, NotificationDialogMixin],
  data () {
    return {}
  },
  computed: {
    headers () {
      return [
        // {
        //   text: '',
        //   value: 'subject'
        // },
        'subject',
        'date_to_string'
        // (this.AppIsSmall ? null : 'date_to_string')
        // {
        //   text: 'read',
        //   value: 'read_at_to_string'
        // }
        // 'control',
      ]
    }
  },
  methods: {
    itemClass (item) {
      return {
        // primary: item.unread,
        grey: item.unread,
        'darken-3': item.unread && this.themeDark,
        'lighten-3': item.unread && this.themeLight,
        pointer: !0
      }
    },
    itemClick (item, { index }) {
      // console.log(index, item)
      this.notificationDialogItem = item
      this.notificationDialogValue = !0

      if (item.unread) {
        this.$nextTick(() => {
          item.read = !0
          item.unread = !1
          // this.updateDatatableItem(item, index)
        })
        // this.updateDatatableItem(item, index)
        // console.log(item)
      }
    }
  }
}
</script>
