<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-col
    cols="12"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <p
      class="mb-3 text-start"
      v-html="label"
    />
  </v-col>
</template>

<script>

export default {
  name: 'TopInputLabel',
  props: {
    label: String
  }
}
</script>
