<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-container
    v-if="authUser"
    class="fill-height"
  >
    <!--<template v-if="dashboardPermission">-->
    <!--  <public-statistics-->
    <!--    ref="publicReport"-->
    <!--    :print="print"-->
    <!--  />-->
    <!--</template>-->
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="auto">
        <v-row justify="center">
          <v-col cols="auto">
            <p class="text-center text-h5">
              {{ $t('replace.hello', { n: authUser.name }) }}
            </p>
            <p class="text-center text-h6">
              {{ authUser.branch_id_to_string }}
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            v-if="customerPermission"
            cols="auto"
          >
            <app-btn
              :to="route(APP_ROUTES.customer.index)"
            >
              {{ $t('replace.go_to', { v: $tc('choice.Customers', 2) }) }}
            </app-btn>
          </v-col>
          <v-col
            v-if="invoicePermission"
            cols="auto"
          >
            <app-btn
              :to="route(APP_ROUTES.invoice.index)"
            >
              {{ $t('replace.go_to', { v: $tc('choice.Invoices', 2) }) }}
            </app-btn>
          </v-col>
          <v-col
            v-if="quotationsPermission"
            cols="auto"
          >
            <app-btn
              :to="route(APP_ROUTES.quotation.index)"
            >
              {{ $t('replace.go_to', { v: $tc('choice.Quotations', 2) }) }}
            </app-btn>
          </v-col>
          <v-col
            v-if="specificationsPermission"
            cols="auto"
          >
            <app-btn
              :to="route(APP_ROUTES.specifications.index)"
            >
              {{ $t('replace.go_to', { v: $tc('choice.Specifications', 2) }) }}
            </app-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PublicStatistics from '@components/home-user/PublicStatistics'

export default {
  name: 'Home',
  components: { PublicStatistics },
  data: () => ({}),
  computed: {
    dashboardPermission () {
      return this.userHasPermission('User.dashboard')
    },
    customerPermission () {
      return this.userHasPermission('Customer.index')
    },
    invoicePermission () {
      return this.userHasPermission('Invoice.index')
    },
    quotationsPermission () {
      return this.userHasPermission('Quotation.index')
    },
    specificationsPermission () {
      return this.userHasPermission('Utilities.Specification.index')
    }
  },
  mounted () {
    if (!this.authUser && window && window.location.pathname !== '/') {
      window.location.href = '/'
    }

    if (this.authUser) {
      setTimeout(() => {
        // if (this.userHasPermission('Quotation.index')) {
        //   this.$router.push({ name: this.APP_ROUTES.quotation.index })
        // } else if (this.userHasPermission('Utilities.Specification.index')) {
        //   this.$router.push({ name: this.APP_ROUTES.specifications.index })
        // }
        // const name = this.APP_ROUTES.quotation.index
        // console.log(this.userHasPermission('Utilities.Specification.index'))
        // this.$nextTick(() => {
        // this.$router.push({ name })
        // })
      }, 600)
    }

    // if (!this.dashboardPermission) {
    //   const name = this.APP_ROUTES.homePage
    //   if (!this.isRoute(name)) {
    //     this.$router.replace({ name })
    //   }
    // }
  },
  methods: {
    print () {
      const publicReport = this.$refs.publicReport?.$el
      if (!publicReport) return

      publicReport.classList.add('d-print-none')
      window.print()
      publicReport.classList.remove('d-print-none')
    }
  }
}
</script>
