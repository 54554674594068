<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-container fluid>
    <v-card
      v-if="attachment"
      :elevation="elevation"
      :height="height"
      :width="width"
      v-bind="{...$attrs,...attrs}"
      v-on="{...$listeners,...on}"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <v-col
            align-self="center"
            class="text-center"
            cols="auto"
          >
            <v-img
              v-if="attachment.type === 'image'"
              :src="attachment.url"
              :lazy-src="AppLogo"
              contain
              max-height="100%"
              max-width="100%"
              width="100%"
              height="100"
            />
            <div
              v-else-if="attachment.type === 'video'"
              style="height: 100px"
              class="d-flex align-center"
            >
              <video
                width="100%"
                height="100%"
                controls
              >
                <source
                  :src="attachment.url"
                  :type="attachment.mime_type"
                >
                Your browser does not support the video tag.
              </video>
            </div>
            <div
              v-else
              style="height: 100px"
              class="d-flex align-center"
            >
              <v-avatar
                color="white"
                rounded
                size="50"
              >
                <v-img
                  :src="iconSource"
                  :width="width*0.5"
                  class="mx-auto"
                />
              </v-avatar>
            </div>

            <p
              class="text font-weight-black ma-0 text-center"
              style="width: 100%;line-clamp: 1"
            >
              {{ attachment.name }}
            </p>
          </v-col>
        </v-row>
        <v-row
          justify="space-between"
          no-gutters
        >
          <v-col cols="auto">
            <v-btn
              :href="attachment.download_url"
              icon
              target="_blank"
            >
              <v-icon>download</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              icon
              @click="deleteFile(attachment.model_id,attachment.id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog
      v-model="loading"
      no-click-animation
      persistent
      width="250"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          &nbsp;
          <v-progress-linear
            class="mb-0"
            color="white"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
  <!--<v-tooltip top>-->
  <!--  <template #activator="{ on, attrs }">-->
  <!--  </template>-->
  <!--  <span>{{ attachment.name }}</span>-->
  <!--</v-tooltip>-->
</template>

<script>

export default {
  name: 'AttachmentBlock',
  props: {
    url: {
      type: Object,
      default: () => undefined
    },
    attachment: {
      type: Object,
      default: () => undefined
    },
    elevation: {
      type: [String, Number],
      default: () => 8
    },
    width: {
      type: [String, Number],
      default: () => '150'
    },
    height: {
      type: [String, Number],
      default: () => 'auto'
    }
  },
  data () {
    return {
      loading: !1,
      iconSource: require('@icons/document.png')
    }
  },
  methods: {
    download () {
      if (this.attachment?.download_url) {
        window.location.href = this.attachment.download_url
      }
    },
    deleteFile (model, id) {
      if (this.loading) return

      this.confirmMessage(async () => {
        this.loading = !0
        try {
          const { _data, _message } = await this.url.deleteAttachment(model, id)
          // _message && this.alertSuccess(_message)
          _data && this.$emit('update-item', _data)
        } catch (e) {
          e?._message && this.alertError(e._message)
        } finally {
          this.loading = !1
        }
      })
    }
  }
}
</script>
<style scoped>
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}
</style>
