/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import { Stub, StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Quotation')
export default {
  ...Stub(url()),
  downloadImportItemsExample: () => axios.get(url('DownloadImportItemsExample')),
  importItems: (form) => axios.post(url('ImportItems'), form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
