/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers('auth')
export default {
  data: () => ({
    modelPermission: ''
  }),
  methods: {
    async setLoginFromResponse ({ token, user }) {
      const resolve = this.$router.resolve(this.route(this.APP_ROUTES.user.home))
      let url = resolve?.resolved?.fullPath || null
      const reload = !url
      // console.log(this.$route)
      if (this.$route.query.fr) {
        url = this.$route.query.fr
      } else {
        url = undefined
      }
      mapActions(['login']).login.call(this, { token, user, reload: false, url })
      // mapActions(['login']).login.call(this, { token, user, reload, url })
      return this.$auth.data()
    },
    loginUser ({ token, user }) {
      this.setLoginFromResponse({ token, user })
      return this.$auth.data()
    },
    logoutUser (reload = !1) {
      reload = Boolean(reload)
      // const resolve = this.$router.resolve(this.route(this.APP_ROUTES.auth.login))
      const resolve = this.$router.resolve(this.route(this.APP_ROUTES.homePage))
      const url = resolve?.resolved.fullPath || null
      reload = url ? !0 : reload
      mapActions(['logout']).logout.call(this, { reload, url })
    },
    async fetchRegisterTypes () {
      let res = []
      try {
        const { _data } = await this.apiService.utilities.registerTypes()
        _data && (res = _data)
      } catch (e) {

      }
      return res || []
    },
    userHasPermission (name) {
      const permissions = this.authUser?.permissions || []
      // console.log(name,permissions)
      name = this.makePermission(name)
      // console.log(name)
      return Boolean(permissions.find(e => e === name))
    },
    checkPermission (name) {
      if (!this.userHasPermission(name)) {
        this.navigate(this.APP_ROUTES.homePage)
      }
    },
    makePermission (permission) {
      const p = this.modelPermission
      return p ? `${this.modelPermission}.${permission}` : permission
    },
    removeLogo () {
      if (this.loading) return
      this.confirmMessage(async () => {
        this.loading = !0
        try {
          const { _data, _message } = await this.apiService.user.destroyAvatar()
          _data && (this.authUser = _data)
          _message && this.alertSuccess(_message)
        } catch (e) {
          const { _message } = e
          this.alertError(_message || e.message)
        } finally {
          this.loading = !1
        }
      })
    }
  },
  computed: {
    authUserAvatar: {
      set (avatar) {
        if (this.authUser) {
          this.authUser = { ...this.authUser, avatar }
        }
      },
      get () {
        return this.authUser.avatar || require('@icons/user.svg')
      }
    },
    isLogin: {
      set (n) {
        n === false && this.logoutUser(true)
      },
      get () {
        return mapGetters(['isLogin']).isLogin.call(this)
      }
    },
    AppReady: {
      set (v) {
        mapMutations(['setAppReady']).setAppReady.call(this, Boolean(v))
      },
      get () {
        return mapGetters(['getAppReady']).getAppReady.call(this)
      }
    },
    isAdminUser () {
      return this.authUser?.is_admin === !0
    }
  }
}
