/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import visitorRoutes from './visitor-routes'
import panelRoutes from './panel-routes'
import authenticationRoutes from './authentication-routes'
import { APP_ROUTES } from '@routes/list'

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "AppLayout" */ '@layouts/AppLayout'),
    children: [
      {
        path: 'home',
        alias: '',
        name: APP_ROUTES.homePage,
        component: () => import(/* webpackChunkName: "MainPage" */ '@pages/HomePage'),
        meta: {
          auth: !0
        }
      },
      ...visitorRoutes,
      ...authenticationRoutes,
      ...panelRoutes
    ]
  }
]
