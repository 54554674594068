<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-navigation-drawer
    :left="!AppRtl"
    :right="AppRtl"
    :value="value"
    app
    class="d-print-none"
    clipped
    floating
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-sheet
      v-if="isFetchItems"
      class="pa-0 pt-5"
      color="transparent"
    >
      <v-responsive
        class="mx-auto"
        max-width="100%"
      >
        <template v-for="i in 7">
          <v-skeleton-loader
            :key="i.toString()"
            class="mx-auto mb-1 px-1"
            type="list-item-avatar"
          />
        </template>
      </v-responsive>
    </v-sheet>
    <app-drawer-list
      v-else
      :items="publicItems"
    />

    <template #prepend>
      <v-skeleton-loader
        v-if="isFetchItems"
        class="px-1 pt-1"
        type="list-item-avatar-two-line"
      />
      <template v-else-if="authUser && !isFetchItems">
        <v-list-item>
          <v-list-item-avatar
            :color="authUser.avatar ? 'black' : undefined "
            :left="!AppRtl"
            :right="AppRtl"
            rounded
            size="60"
          >
            <v-img
              :src="authUserAvatar"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <router-link
                :to="route(APP_ROUTES.user.profile)"
                class="text-body-1 text-decoration-underline accent--text"
              >
                {{ $t('replace.hello', { n: authUser.name }) }}
              </router-link>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="">
                {{ authUser.username }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="primary" />
      </template>
    </template>

    <template #append>
      <v-divider class="primary" />
      <app-theme-list
        icon
      />
      <!--<v-divider class="primary" />-->
      <app-language-list icon />
      <v-sheet
        v-if="isFetchItems"
        class="logout-sheet pa-2"
        color="transparent"
      >
        <v-skeleton-loader
          type="button"
        />
      </v-sheet>
      <template
        v-else-if="authUser"
      >
        <app-btn
          tile
          block
          color="error"
          @click.stop="logout"
          v-text="$t('logout')"
        />
      </template>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { hideHtmlOverflow, showHtmlOverflow } from '@helpers/Tools'

export default {
  name: 'Navigation',
  props: {
    value: {
      type: Boolean,
      default: () => undefined
    }
  },
  data () {
    return {
      logoutLoading: !1
    }
  },
  computed: {
    isFetchItems: {
      set (v) {
        mapMutations('sideMenu', ['setFetching']).setFetching.call(this, v)
      },
      get () {
        return mapGetters('sideMenu', ['getFetching']).getFetching.call(this)
      }
    },
    publicItems () {
      const auth = [
        {
          name: this.APP_ROUTES.auth.login,
          title: this.$t('login'),
          // icon: '',
          center: !1,
          auth: !0
        },
        // {
        //   name: this.APP_ROUTES.auth.register,
        //   title: this.$t('register'),
        //   // icon: '',
        //   center: !1,
        //   auth: !0
        // },
        { divider: !0 }
      ]

      let items = [
        // {
        //   name: this.APP_ROUTES.homePage,
        //   title: this.$t('home'),
        //   icon: 'home'
        // },
        // {
        //   name: this.APP_ROUTES.public.aboutUs,
        //   title: this.$t(this.APP_ROUTES.public.aboutUs),
        //   icon: 'info'
        // },
        // {
        //   name: this.APP_ROUTES.public.contactUs,
        //   title: this.$t(this.APP_ROUTES.public.contactUs),
        //   icon: 'phone'
        // },
        // {
        //   name: this.APP_ROUTES.public.privacyPolicy,
        //   title: this.$t(this.APP_ROUTES.public.privacyPolicy),
        //   icon: 'privacy_tip'
        // },
        // {
        //   name: this.APP_ROUTES.public.termsAndConditions,
        //   title: this.$t(this.APP_ROUTES.public.termsAndConditions),
        //   icon: 'gavel'
        // }
      ]

      if (!this.authUser) {
        items = [...auth, ...items]
      }

      return [
        ...this.AppSideMenu, ...items]
    }

  },
  watch: {
    value (v) {
      if (v === !0) {
        hideHtmlOverflow()
      }
      if (v === !1) {
        showHtmlOverflow()
      }
    }
  },
  mounted () {
    // console.log(this.publicItems)
  },
  methods: {
    logout () {
      this.logoutLoading = !0
      this.confirmMessage(this.$t('messages.sure_logout'), async () => {
        await this.apiService.auth.logout({ push_token: window.push_token || null }).finally(() => this.logoutUser(true))
      }, () => {
        this.logoutLoading = !1
      })
    }
  }
}
</script>
<style scoped>
.logout-sheet .v-skeleton-loader__button {
  width: 100% !important;
}
</style>
