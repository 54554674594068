<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <ValidationObserver
    v-slot="v"
    :ref="name"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-form
      ref="vForm"
      @submit.prevent="v.handleSubmit(() => submit ? submit(v) : null)"
    >
      <slot v-bind="v" />
      <button
        ref="vSubmit"
        class="d-none"
        disabled
        type="submit"
      />
    </v-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'Form',
  props: {
    submit: {
      type: Function,
      default: () => () => null,
      required: !1
    },
    name: {
      type: String,
      default: () => 'form'
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    form () {
      return this.$refs[this.name]
    }
  },
  watch: {
    errors: {
      deep: true,
      handler (errors, old) {
        this.setErrors(errors || {})
      }
    }
  },
  mounted () {
    // console.log(this.submit)
  },
  methods: {
    reset (...args) {
      this.form && this.form.reset(...args)
    },
    validate (...args) {
      this.form && this.form.validate(...args)
    },
    setErrors (...args) {
      this.form && this.form.setErrors(...args)
    },
    submitForm () {
      return this.$refs.vForm.$el.submit()
    }
  }
}
</script>

<style scoped>

</style>
