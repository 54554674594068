<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-row dense>
    <v-col cols="12">
      <slot name="before" />
    </v-col>
    <v-col cols="12">
      <v-row>
        <app-text-input
          v-model="form.username"
          :disabled="profile"
          :hint="$t('hints.username')"
          :readonly="profile"
          cols="12"
          md="6"
          name="username"
          persistent-hint
          required
        />
        <app-text-input
          v-model="form.name_ar"
          :disabled="profile"
          :readonly="profile"
          cols="12"
          md="6"
          name="name_ar"
          persistent-hint
          required
        />
        <app-text-input
          v-model="form.name_en"
          :disabled="profile"
          :readonly="profile"
          cols="12"
          md="6"
          name="name_en"
          persistent-hint
          required
        />
        <app-mobile-input
          v-model="form.mobile"
          :disabled="profile"
          :hint="$t('hints.mobile')"
          :readonly="profile"
          clearable
          cols="12"
          md="6"
          name="mobile"
          persistent-hint
        />
        <app-text-input
          v-model="form.email"
          :disabled="profile"
          :readonly="profile"
          clearable
          cols="12"
          md="6"
          name="email"
          rules="email"
        />
        <slot />
      </v-row>
    </v-col>
    <v-col cols="12">
      <slot name="after" />
    </v-col>
  </v-row>
</template>
<script>

import SignatureForm from '@components/user/SignatureForm'
export default {
  name: 'AccountForm',
  components: { SignatureForm },
  props: {
    value: {
      type: Object,
      default: () => undefined
    },
    profile: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {}
  },
  computed: {
    form: {
      set (v) {
        this.$emit('input', v)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>
