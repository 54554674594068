/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

const Auth = {

  /**
   * Localstorage Key
   *
   * @type string
   */
  localstorageKey: 'authentication_key',

  /** Default localstorage state */
  default: { token: null, user: null },

  /**
   * Must set this value from back-end user
   * User role key value
   * @string
   */
  role_key: 'role_code',

  /**
   * Get localstorage state
   *
   * @return any
   */
  data (key = null) {
    const k = this.default
    const o = JSON.parse(localStorage.getItem(this.localstorageKey) || JSON.stringify(k)) || k
    if (key === null) return o
    return o[key]
  },

  /**
   * Save localstorage state
   * @param attributes
   */
  save (attributes = {}) {
    const storageData = this.data()
    const save = { ...attributes }
    Object.keys(this.default).forEach(k => save[k] = attributes[k] !== undefined ? attributes[k] : storageData[k])

    localStorage.setItem(this.localstorageKey, JSON.stringify(save))
  },

  /**
   * get access token from localstorage
   * @returns {string}
   */
  getAccessToken () {
    const { token } = this.data()
    return token || this.default.token
  },

  /**
   * get user data from localstorage
   * @returns {Object}
   */
  getUserData () {
    const { user } = this.data()
    return user || this.default.user
  },

  /**
   * is login from localstorage
   * @returns {boolean}
   */
  isLogin () {
    const { token = '' } = this.data()
    return token?.length > 0
  },

  /**
   * Remove localstorage
   */
  logout () {
    localStorage.removeItem(this.localstorageKey)
  },

  /**
   * check user role
   * @returns {boolean}
   */
  is () {
    const { user } = this.data() || this.default
    if (!user) return false
    let t = user[this.role_key] || ''
    let role = arguments.length === 1 ? arguments[0] : [...arguments]
    role = typeof role !== 'object' ? role.split(',') : Object.values(role)
    t = t.toLocaleLowerCase().trim()
    role.map((i, item) => item.toString().toLocaleLowerCase().trim())
    return role.indexOf(t) > -1
  },

  /**
   *
   * @param key
   * @param value
   * @returns {Auth}
   */
  set (key, value) {
    const data = this.data()
    data[key] = value
    this.save(data)
    return this
  },

  /**
   *
   * @param key
   * @returns {*|{token: null}}
   */
  get (key) {
    return this.data(key)
  }
}

export default Auth
