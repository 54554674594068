/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  year: 'سنة {n}',
  choose: 'اختر {n}',
  hello: 'مرحبا {n}',
  store: 'إضافة {n}',
  update: 'تعديل بيانات {n}',
  show: 'عرض بيانات {n}',
  destroy: 'حذف بيانات {n}',
  role_permissions: 'الصلاحيات: مجموعة {n}',
  yearsOf: 'سنوات {n}',
  browse: 'تصفح {v}',
  go_to: 'الذهاب إلى {v}'
}
