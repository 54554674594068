<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-row
    :class="getClasses"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: 'Row',
  computed: {
    getClasses: () => [
      'app-row'
    ].join(' ')
  }
}
</script>
