<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <vue-recaptcha
    :execute="execute"
    :sitekey="siteKey"
  ></vue-recaptcha>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { GOOGLE } from '@config'

export default {
  name: 'ReCaptcha',
  components: { VueRecaptcha },
  props: {},
  computed: {},
  data: () => ({ siteKey: GOOGLE.reCaptchaKey }),
  methods: {
    execute () {
      // console.log(arguments)
    }
  }
}
</script>
