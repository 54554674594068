<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-dialog-loading.sync="formDialogLoading"
      :form-dialog.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
    >
      <template #filter="{datatableFilters}">
        <v-row>
          <app-users
            v-if="isAdminUser"
            v-model="datatableFilters.user_id"
            clearable
            cols="12"
            hide-details
            name="user_id"
            sm="6"
          />
          <app-branches
            v-model="datatableFilters.brand_id"
            clearable
            cols="12"
            hide-details
            name="brand_id"
            sm="6"
          />
        </v-row>
        <v-row>
          <app-date-picker
            v-model="datatableFilters.from_created_at"
            cols="12"
            hide-details
            name="from_created_at"
            sm="6"
          />
          <app-date-picker
            v-model="datatableFilters.to_created_at"
            cols="12"
            hide-details
            name="to_created_at"
            sm="6"
          />
        </v-row>
      </template>

      <template #formDialog="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <app-row>
            <app-users
              v-if="isAdminUser"
              v-model="selectedItem.user_id"
              cols="12"
              name="user_id"
              required
              sm="6"
            />
            <app-brands
              v-model="selectedItem.brand_id"
              cols="12"
              name="brand_id"
              required
              sm="6"
              @set-items="brands = $event"
            />
            <app-auto-select
              v-model="selectedItem.brand_item_id"
              :items="brandItems"
              cols="12"
              name="brand_item_id"
              required
              sm="6"
            />
            <app-auto-select
              v-model="selectedItem.model"
              :items="getModels"
              cols="12"
              name="model"
              required
              sm="6"
            />
            <app-textarea-input
              v-model="selectedItem.item_specifications"
              clearable
              cols="12"
              name="item_specifications"
            />
          </app-row>
          <app-row>
            <app-text-input
              v-model="selectedItem.youtube_url"
              clearable
              cols="12"
              name="youtube_url"
              :hint="parseAttribute('video')"
              persistent-hint
            />
          </app-row>
          <app-row v-if="!isNewItem">
            <attachments-container
              :attachments.sync="selectedItem.attachments"
              :item="selectedItem"
              :show-destroy="userHasPermission('Attachment.destroy')"
              :show-upload="userHasPermission('Attachment.upload')"
              :urls="apiService.specification"
              accept="application/pdf,image/*"
              multiple
            />
          </app-row>
          <app-row>
            <v-col cols="12">
              <h4 v-if="isNewItem">
                {{ $t('hints.specifications_hint') }}
              </h4>
            </v-col>
            <v-col>
              <app-btn
                :block="AppIsSmall"
                :loading="formDialogLoading"
                @click="submitClickForm"
              >
                {{ $t('save') }}
              </app-btn>
            </v-col>
          </app-row>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('show')"
          :disabled="loadingDatatable"
          @click="copyUrl(item)"
        >
          {{ $t('copy_specifications') }}
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('show')"
          :disabled="loadingDatatable"
          @click="copyUrl(item,!0)"
        >
          {{ $t('copy_specifications_with_name') }}
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('export')"
          :disabled="loadingDatatable"
          @click="exportItem(item)"
        >
          {{ $t('export') }}
          <!--<v-icon>content_copy</v-icon>-->
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('show')"
          :disabled="loadingDatatable"
          show
          @click="viewModel(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('update')"
          :disabled="loadingDatatable"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          :disabled="loadingDatatable"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
    <v-dialog
      v-model="showSpecification.value"
      fullscreen
    >
      <v-container
        class="fill-height pa-0"
        fluid
      >
        <v-card
          flat
          height="100%"
          width="100%"
        >
          <app-btn @click="showSpecification = {value: !1,item:null}">
            {{ $t('close') }}
          </app-btn>
          <iframe
            v-if="showSpecification.url"
            :src="showSpecification.url"
            height="95%"
            style="border: 0"
            width="100%"
          />
        </v-card>
      </v-container>
      <!--<v-card v-if="showSpecification.item" elevation="0" flat tile>-->
      <!--  <v-card-text>-->
      <!--    <view-specification :id="showSpecification.item.id" />-->
      <!--  </v-card-text>-->
      <!--  <v-card-actions class="d-print-none">-->
      <!--    <app-btn @click="showSpecification = {value: !1,item:null}">-->
      <!--      {{ $t('close') }}-->
      <!--    </app-btn>-->
      <!--  </v-card-actions>-->
      <!--</v-card>-->
    </v-dialog>
  </app-card>
</template>
<script>
import { DatatableMixin, MetaInfoMixin } from '@mixins'
import AttachmentsContainer from '@components/attachments/AttachmentsContainer'
import { APP_ROUTES } from '@routes/list'
import Api from '@app/api'

export default {
  name: 'Specifications',
  components: { AttachmentsContainer },
  helperApiName: 'specification',
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      showSpecification: {
        value: !1,
        item: null,
        url: null
      },
      modelPermission: 'Utilities.Specification',
      brands: []
    }
  },
  computed: {
    headers () {
      return [
        (this.isAdminUser ? 'user_id_to_string' : null),
        'brand_id_to_string',
        'brand_item_id_to_string',
        'model',
        (this.isAdminUser ? 'created_at_to_string' : null),
        'control'
      ]
    },
    defaultSelectedItem () {
      return {
        attachments: []
      }
    },
    getModels () {
      const v = []
      let i = 2010
      for (; i <= 2040; ++i) {
        v.push(i)
      }
      return v
    },
    brandItems () {
      return this.brands.find(e => e.id === this.selectedItem.brand_id)?.brand_items || []
    }
  },
  mounted () {
    this.checkPermission('index')
  },
  methods: {
    viewModel (item) {
      const url = `${window.location.protocol}//${window.location.host}${this.$router.resolve({
        name: APP_ROUTES.public.viewSpecification,
        params: { id: item.id }
      }).href}`
      this.showSpecification = {
        value: !0,
        url,
        item
      }
      return
      this.$router.push({
        name: APP_ROUTES.public.viewSpecification,
        params: { id: item.id, backBtn: !0 }
        // params: { item, backBtn: !0 }
      })
    },
    copyUrl (item, withName = !1) {
      const t = ''
      const url = `${window.location.protocol}//${window.location.host}${this.$router.resolve({
        name: APP_ROUTES.public.viewSpecification,
        params: { id: item.id }
      }).href}`
      this.copyText((withName ? (`${item.brand_item_id_to_string}${(item.model ? ` ${item.model}` : '')}` + '\n') : '') + `${url}`)

      // `${item.brand_item_id_to_string}${(item.model || '')}`

      this.alertSuccess(this.$t('specificationPage.link_copied'))
    },
    exportItem (item) {
      if (this.loadingDatatable) {
        return
      }
      this.confirmMessage(() => {
        this.loadingDatatable = !0
        Api.methods.specification.export(item.id)
          .then(({ _data }) => {
            if (_data.url) {
              window.open(_data.url)
            }
          })
          .catch(e => {
            e?._message && this.alertError(e._message)
          }).finally(() => (this.loadingDatatable = !1))
      })
    }
  }
}
</script>
