/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import _ from 'lodash'
import moment from 'moment-hijri'
import qs from 'qs'
import goTo from 'vuetify/es5/services/goto'

function appendArray (formData, values, name) {
  let value
  if (values instanceof File || values instanceof Blob) {
    formData.append(name, values, values.name)
  } else if (typeof values === 'object') {
    for (const key in values) {
      value = values[key]
      if (value !== null && value !== undefined && typeof value === 'object') {
        const k = name ? name + '[' + key + ']' : key
        if (_.isArray(value) && value.length < 1) {
          // console.log(_.isArray(value), _.isArray({}))
          // console.log(name, k, key, value)
          formData.append(`${key}`, '')
        } else {
          appendArray(formData, value, k)
        }
      } else {
        if (value === true) {
          value = 1
        }
        if (value === false) {
          value = 0
        }
        if (value === null || value === undefined) {
          value = ''
          // console.log('null----', name,key, value)
        }
        // if (value !== undefined) {
        if (name) {
          formData.append(name + '[' + key + ']', value)
        } else {
          formData.append(key, value)
        }
        // }
      }
    }
  } else {
    console.log(values, name)
  }

  return formData
}

export const Tools = {
  appendArray,

  goTo (target = 0, GoToOptions = {}) {
    GoToOptions = {
      easing: 'linear',
      duration: 500,
      ...GoToOptions
    }
    return goTo(target, GoToOptions)
  },
  isString: (...value) => _.isString(...value),
  isBoolean: (...value) => _.isBoolean(...value),
  isFunction: (...value) => _.isFunction(...value),
  isArray: (...value) => _.isArray(value) || _.isObject(value),
  isOnlyArray: (...value) => _.isArray(...value),
  isOnlyObject: (...value) => _.isObject(...value),
  isNumeric: v => !isNaN(parseFloat(v)) && isFinite(v) && v.constructor !== Array,

  isKsaMobile: mobile => {
    mobile = Tools.fromArabicNumber(mobile) || ''
    if (!mobile) return !1
    const c1 = mobile.substr(0, 2)
    // const c2 = parseInt(mobile.substr(0, 1))
    return mobile && (mobile.length === 10 && (c1 === '05' || parseInt(c1) === 5))
    // return mobile && ((mobile.length === 10 && (c1 === '05' || parseInt(c1) === 5)) || (mobile.length === 9 && c2 === 5))
  },
  findBy (search, value, column = 'id') {
    return _.find(search, e => this.isArray(e) ? e[column] === value : e === value)
  },
  queryStringify: v => new URLSearchParams(qs.stringify(v, {
    arrayFormat: 'indices'
    // encodeValuesOnly: true,
    // encode: false,
  })),
  flipChoice (data) {
    const f = {}
    for (const a in data) {
      let c
      c = data[a].split('|')
      c = [c[1], c[0]].join('|')
      f[a] = c
    }
    return f
  },
  downloadFromResponse (response, vm) {
    try {
      if (response?.data?.data?.url) {
        const url = response?.data?.data?.url
        if (vm) {
          vm.openWindow(url)
        } else {
          window.open(url, 'AppWindow')
        }
        return
      }

      if (!response) return
      // let f = document.createElement('div');
      // document.body.appendChild (f)
      // f.innerHTML = response.data
      // return;
      let name = response.headers['content-disposition'] || ''
      // console.log(response.headers['Content-Disposition'])
      // name = name.split('filename=').pop().replace(/^\"+|\"+$/g, '');
      name = name.split('filename=').pop().replace(/^"+|"+$/g, '')
      // console.log(response)
      // name = name ||
      if (!name) {
        alert('No file name')
        console.log(response.headers)
        return
      }

      const file = new Blob([response.data])
      // console.log(file.type)
      const fileURL = window.URL.createObjectURL(file)
      const fileLink = document.createElement('a')
      if (!fileLink || !fileURL) return

      fileLink.href = fileURL
      fileLink.setAttribute('download', name)
      document.body.appendChild(fileLink)
      fileLink.click()
      setTimeout(() => {
        try {
          document.body.removeChild(fileLink)
          URL.revokeObjectURL(fileURL)
        } catch (e) {

        }
      }, 3000)
    } catch (e) {
      console.log(e)
    }
  },

  fromArabicNumber (n) {
    if (!n) return n
    n = '' + n
    n = n.replace(/٠/g, 0).replace(/١/g, 1).replace(/٢/g, 2).replace(/٣/g, 3).replace(/٤/g, 4).replace(/٥/g, 5).replace(/٦/g, 6).replace(/٧/g, 7).replace(/٨/g, 8).replace(/٩/g, 9)

    return '' + n
  },
  toNumber (n) {
    if (!n) return n
    n = '' + n
    n = this.fromArabicNumber(n)
    // n = n.replace(/\,/g, '');
    n = n.replace(/,/g, '')
    // .replace(/[^\d+.-]/g, "");

    return '' + n
  },
  toNumberFormat (num = null, c) {
    // console.log(num);

    if (!num) {
      // console.log(num)
      return num
    }

    const splitArray = this.toNumber(num).split('.')
    const rgx = /(\d+)(\d{3})/

    while (rgx.test(splitArray[0])) {
      splitArray[0] = splitArray[0].replace(rgx, '$1' + ',' + '$2')
    }
    // console.log(splitArray);
    let t = splitArray.join('.')
    if (c) t = `${t} ${c}`
    return t
  },
  hijriYear () {
    const l = moment().locale()
    moment.locale('en')
    const date = moment().format('iYYYY')
    moment.locale(l)
    return date
  },
  hijriMonth () {
    const l = moment().locale()
    moment.locale('en')
    const date = moment().format('iM')
    moment.locale(l)
    return date
  },
  hijriDay () {
    const l = moment().locale()
    moment.locale('en')
    const date = moment().format('iD')
    moment.locale(l)
    return date
  },
  todayDate () {
    const l = moment().locale()
    moment.locale('en')
    const date = moment().format('YYYY-MM-DD')
    moment.locale(l)
    return date
  },
  todayTime () {
    const l = moment().locale()
    moment.locale('en')
    const time = moment().format('HH:mm')
    moment.locale(l)
    return time
  },
  getYear () {
    return new Date().getFullYear()
  },

  sortNumberFormatted (a, b) {
    const f = parseFloat(Tools.toNumber(a).replace(/[^\d]/g, '')) || 0
    const s = parseFloat(Tools.toNumber(b).replace(/[^\d]/g, '')) || 0
    return f - s
  }
}

export const hideHtmlOverflow = () => {
  document.documentElement.classList.remove('overflow-y-auto')
  document.documentElement.classList.add('overflow-hidden')
  document.documentElement.classList.add('overflow-y-hidden')
}

export const showHtmlOverflow = () => {
  document.documentElement.classList.add('overflow-y-auto')
  document.documentElement.classList.remove('overflow-hidden')
  document.documentElement.classList.remove('overflow-y-hidden')
}
export const disableHtmlSelect = () => {
  document.documentElement.classList.add('user-select-none')
}
