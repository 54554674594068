<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-row>
    <app-cities
      v-if="!guest"
      v-model="form.city_id"
      ref="cityRef"
      :clearable="cityClearable"
      :cols="cols"
      :lg="lg"
      :md="md"
      :multiple="cityMultiple"
      :required="cityRequired"
      :rules="cityRules"
      :sm="sm"
      name="city_id"
      @change="changeCity"
      @set-items="setCities"
    />
    <app-cities
      v-else
      v-model="form.city_id"
      ref="cityRef"
      :clearable="cityClearable"
      :cols="cols"
      :lg="lg"
      :md="md"
      :multiple="cityMultiple"
      :required="cityRequired"
      :rules="cityRules"
      :sm="sm"
      name="city_id"
      @change="changeCity"
      @set-items="setCities"
    />
    <app-auto-select
      v-model="form.neighborhood_id"
      ref="neighborhoodRef"
      :clearable="neighborhoodClearable"
      :cols="cols"
      :items="neighborhoods"
      :lg="lg"
      :md="md"
      :required="neighborhoodRequired"
      :rules="neighborhoodRules"
      :sm="sm"
      hide-selected
      name="neighborhood_id"
      single-line
    />
    <slot />
  </v-row>
</template>
<script>
export default {
  name: 'CitiesContainer',
  props: {
    value: {
      type: [Object],
      // default: () => ({}),
      default: () => undefined
    },
    cols: {
      type: [String, Number],
      default: () => undefined
    },
    lg: {
      type: [String, Number],
      default: () => undefined
    },
    md: {
      type: [String, Number],
      default: () => undefined
    },
    sm: {
      type: [String, Number],
      default: () => undefined
    },
    cityRules: {
      type: [String, Array, Object],
      default: () => undefined
    },
    cityRequired: {
      type: Boolean,
      default: () => false
    },
    cityMultiple: {
      type: Boolean,
      default: () => false
    },
    cityClearable: {
      type: Boolean,
      default: () => false
    },
    neighborhoodRules: {
      type: [String, Array, Object],
      default: () => undefined
    },
    neighborhoodRequired: {
      type: Boolean,
      default: () => false
    },
    neighborhoodClearable: {
      type: Boolean,
      default: () => false
    },
    guest: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      form: {},
      cities: []
    }
  },
  computed: {
    currentCity () {
      if (!this.value) return
      if (this.value.city_id && typeof this.value.city_id === 'object') {
        return this.cities.filter(c => this.value.city_id.indexOf(c.id) !== -1)
      }

      return this.value.city_id ? this.cities.find(c => c.id === this.value.city_id) : undefined
    },
    neighborhoods () {
      if (this.currentCity && this.$helpers.isOnlyArray(this.currentCity)) {
        if (this.currentCity.length !== 1) {
          return []
        }

        return this.currentCity[0].neighborhoods

        // neighborhoods = []
        // this.currentCity.forEach(e => {
        //   neighborhoods = [...neighborhoods, ...(e.neighborhoods || [])]
        // })
        // return neighborhoods
      }
      return this.currentCity ? this.currentCity.neighborhoods : []
    }
  },
  watch: {
    form: {
      immediate: !0,
      deep: !0,
      handler (v) {
        // let args = { ...v,...(this.value || {}) }
        // this.value && (args = { ...this.value, ...args })
        // console.log(v)
        // this.$emit('input', { ...(v || {}), ...(this.value || {}) })
        this.$emit('input', v)
      }
    }
  },
  mounted () {
    this.form = this.value
  },
  methods: {
    changeCity (id) {
      if (this.$refs.cityRef) {
        const city = id && this.$refs.cityRef ? this.$refs.cityRef.getItems.find(c => c.id === id) : []
        // console.log(id, city)
      }
      // console.log('changeCity', this.$refs.neighborhoodRef)
      this.$refs.neighborhoodRef && this.$refs.neighborhoodRef.$emit('input', null)
    },
    setCities (v) {
      this.cities = v
    }
  }
}
</script>
