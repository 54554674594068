/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import axios from 'axios'
import { StubUrl } from '../Stub'

const url = StubUrl('Setting')
export default {
  all: () => axios.get(url()),
  save: data => axios.post(url(), data)
}
