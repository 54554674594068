/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  name: 'Settings',
  general: 'General',
  notifications: 'Notifications',
  contact: 'Contact'
}
