/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import names from './attributes'
const messages = require('vee-validate/dist/locale/en.json')

export default {
  code: 'en',
  messages: {
    ...messages,
    int: 'The {_field_} field must be a valid integer',
    mobile: 'The {_field_} must be a valid mobile number'
  },
  names,
  fields: {}
}
