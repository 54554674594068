/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import { APP_ROUTES } from '@routes/list'

let routes = []

const requireModule = require.context('@routes', true, /\.js$/)

requireModule.keys().forEach(fileName => {
  if (fileName.split('/').pop() !== 'index.js') return

  const m = requireModule(fileName)
  const l = m.default || m
  routes = [...routes, ...l]
})

/** Logout route */
routes.push({
  path: '/logout',
  name: APP_ROUTES.auth.logout,
  component: () => import(/* webpackChunkName: "LogoutPage" */ '@components/Logout')
})

/** Not Found route */
routes.push({
  path: '*',
  name: APP_ROUTES.notFound,
  component: () => import(/* webpackChunkName: "FourOuFour" */ '@components/FourOuFour')
})
export default routes
