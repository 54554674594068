<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-card :title="$t('settingsPage.name')">
    <v-container
      v-if="loading"
      class="fill-height"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader
            type="article,paragraph,actions"
          />
        </v-col>
      </v-row>
    </v-container>
    <app-form
      v-else
      v-slot="v"
      ref="form"
      :errors="errors"
      :submit="submit"
    >
      <v-container
        class="mb-10"
      >
        <v-tabs v-model="tab">
          <v-tab>{{ $t('settingsPage.general') }}</v-tab>
          <!--<v-tab>{{ parseAttribute('quotation_id') }}</v-tab>-->
          <!--<v-tab>{{ $t('settingsPage.notifications') }}</v-tab>-->
          <!--<v-tab>{{ $t('settingsPage.contact') }}</v-tab>-->

          <v-tab-item>
            <v-container
              class="fill-height"
              fluid
            >
              <v-row>
                <app-text-input
                  v-model="form.app_name_ar"
                  sm="6"
                  name="app_name_ar"
                />
                <app-text-input
                  v-model="form.app_name_en"
                  sm="6"
                  name="app_name_en"
                />
              </v-row>
              <v-row>
                <app-text-input
                  v-model="form.commercial_name_ar"
                  clearable
                  sm="6"
                  name="commercial_name_ar"
                />
                <app-text-input
                  v-model="form.commercial_name_en"
                  clearable
                  sm="6"
                  name="commercial_name_en"
                />
                <app-number-input
                  v-model="form.commercial_register"
                  clearable
                  sm="6"
                  name="commercial_register"
                />
                <app-number-input
                  v-model="form.vat_number"
                  clearable
                  sm="6"
                  name="vat_number"
                />
                <app-float-input
                  v-model="form.tax"
                  clearable
                  sm="6"
                  name="tax"
                  prefix="%"
                />
                <!--<app-number-input-->
                <!--  v-model="form.traffics_licence"-->
                <!--  clearable-->
                <!--  sm="6"-->
                <!--  name="traffics_licence"-->
                <!--/>-->
              </v-row>
              <v-row>
                <app-textarea-input
                  v-model="form.description_ar"
                  clearable
                  cols="12"
                  name="description_ar"
                />
                <app-textarea-input
                  v-model="form.description_en"
                  clearable
                  cols="12"
                  name="description_en"
                />
              </v-row>
              <v-row>
                <app-textarea-input
                  v-model="form.address_ar"
                  clearable
                  cols="12"
                  name="address_ar"
                />
                <app-textarea-input
                  v-model="form.address_en"
                  clearable
                  cols="12"
                  name="address_en"
                />
              </v-row>
            </v-container>
          </v-tab-item>
          <!--<v-tab-item>
            <v-container
              class="fill-height"
              fluid
            >
              <v-row>
                <app-textarea-input
                  v-model="form.quotation_terms_ar"
                  clearable
                  cols="12"
                  name="quotation_terms_ar"
                />
                <app-textarea-input
                  v-model="form.quotation_terms_en"
                  clearable
                  cols="12"
                  name="quotation_terms_en"
                />
              </v-row>
            </v-container>
          </v-tab-item>-->
          <!--<v-tab-item>
            <v-container
              class="fill-height"
              fluid
            >
              <v-row>
                <app-auto-select
                  v-model="form.notification_methods"
                  :items="notification_methods"
                  cols="12"
                  sm="6"
                  multiple
                  name="notification_methods"
                  required
                />
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span class="text-body-1">{{ parseAttribute('notification_email') }}</span>
                  <v-btn
                    color="primary"
                    icon
                    @click="addNotificationEmail"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <template
                  v-for="(m,i) in notification_email"
                >
                  <app-text-input
                    v-model="notification_email[i]"
                    :key="i.toString()"
                    append-outer-icon="clear"
                    lg="3"
                    md="5"
                    name="notification_email"
                    required
                    @click:append-outer="removeNotificationEmail(i)"
                  />
                </template>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span class="text-body-1">{{ parseAttribute('notification_mobile') }}</span>
                  <v-btn
                    color="primary"
                    icon
                    @click="addNotificationMobile"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <template
                  v-for="(m,i) in notification_mobile"
                >
                  <app-number-input
                    v-model="notification_mobile[i]"
                    :key="i.toString()"
                    append-outer-icon="clear"
                    lg="3"
                    md="5"
                    name="notification_mobile"
                    required
                    @click:append-outer="removeNotificationMobile(i)"
                  />
                </template>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <app-text-input
                  v-model="form.customer_service_email"
                  clearable
                  sm="6"
                  name="customer_service_email"
                />
                <app-number-input
                  v-model="form.customer_service_mobile"
                  clearable
                  sm="6"
                  name="customer_service_mobile"
                />
                <app-number-input
                  v-model="form.customer_service_phone"
                  clearable
                  sm="6"
                  name="customer_service_phone"
                />
              </v-row>
              <v-row>
                <app-text-input
                  v-model="form.web"
                  clearable
                  sm="6"
                  name="web"
                />
                <app-text-input
                  v-model="form.whatsapp"
                  clearable
                  sm="6"
                  name="whatsapp"
                />
                <app-text-input
                  v-model="form.twitter"
                  clearable
                  sm="6"
                  name="twitter"
                />
                <app-text-input
                  v-model="form.instagram"
                  clearable
                  sm="6"
                  name="instagram"
                />
                <app-text-input
                  v-model="form.snapchat"
                  clearable
                  sm="6"
                  name="snapchat"
                />
                <app-text-input
                  v-model="form.facebook"
                  clearable
                  sm="6"
                  name="facebook"
                />
                <app-text-input
                  v-model="form.linkedin"
                  clearable
                  sm="6"
                  name="linkedin"
                />
                <app-text-input
                  v-model="form.google_map_url"
                  clearable
                  sm="6"
                  name="google_map_url"
                />
                <app-text-input
                  v-model="form.app_store_url"
                  clearable
                  sm="6"
                  name="app_store_url"
                />
                <app-text-input
                  v-model="form.play_store_url"
                  clearable
                  sm="6"
                  name="play_store_url"
                />
              </v-row>
            </v-container>
          </v-tab-item>-->
        </v-tabs>
        <v-row>
          <v-col>
            <app-btn
              :disabled="v.invalid"
              :loading="saving"
              @click="submit"
            >
              {{ $t('save') }}
            </app-btn>
          </v-col>
        </v-row>
      </v-container>
    </app-form>
  </app-card>
</template>

<script>

import { GetHeadersMixin } from '@mixins'

export default {
  name: 'Settings',
  mixins: [GetHeadersMixin],
  data () {
    return {
      loading: !1,
      saving: !1,
      tab: 0,
      errors: {},
      form: {},
      notification_email: [],
      notification_mobile: [],
      notification_methods: this.parseHeaders(['database', 'mail', 'sms', 'push_token'])
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      if (this.loading) return
      this.loading = !0
      this.fetchAllAppSettings().then(({ _data }) => this.mountSetting(_data)).catch(r => r).finally(() => this.loading = !1)
    },
    async saveData (form) {
      if (this.saving) return
      try {
        this.saving = !0
        const { _data, _message } = await this.apiService.setting.save(form)
        _message && this.alertSuccess(_message)
        this.mountSetting(_data)
      } catch (e) {
        const { _errors, _message } = e
        this.errors = _errors || {}
        _message && this.alertError(_message)
      } finally {
        this.saving = !1
      }
    },
    submit () {
      const form = this.form
      form.notification_email = this.notification_email
      form.notification_mobile = this.notification_mobile
      this.saveData(form)
    },
    mountSetting (data) {
      this.form = data || {}
      this.notification_email = this.form.notification_email || []
      this.notification_mobile = this.form.notification_mobile || []
    },
    addNotificationMobile () {
      this.notification_mobile = [
        ...this.notification_mobile,
        ''
      ]
    },
    removeNotificationMobile (index) {
      this.confirmMessage(() => (this.notification_mobile = this.notification_mobile.filter((v, i) => i !== index)))
    },
    addNotificationEmail () {
      this.notification_email = [
        ...this.notification_email,
        ''
      ]
    },
    removeNotificationEmail (index) {
      this.confirmMessage(() => (this.notification_email = this.notification_email.filter((v, i) => i !== index)))
    }
  }
}
</script>
