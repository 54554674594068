<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-tooltip
    :bottom="tooltipBottom"
    top
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #activator="{ on, attrs }">
      <span
        :class="activatorClass"
        v-bind="attrs"
        v-on="on"
      >
        <slot />
      </span>
    </template>
    <span v-if="text">{{ text }}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: 'Tooltip',
  props: {
    text: {
      type: [String],
      default: () => undefined
    },
    tooltipBottom: {
      type: Boolean,
      default: () => !1
    }
  },
  computed: {
    activatorClass () {
      return ['d-inline-flex'].join(' ')
    },
    getAttrs () {
      return { top: true, ...this.$attrs }
    }
  }
}
</script>
