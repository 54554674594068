<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-system-bar
    :class="getClasses"
    :dark="themeLight"
    app
  >
    <span :class="spanClass">{{ day }}</span>
    <span :class="spanClass">{{ date }}</span>
    <span :class="spanClass">{{ hijri }}</span>
    <span>{{ time }}</span>
    <v-spacer />
    <app-system-bar-btn @click="$root.themeDark = !themeDark">
      <v-icon>mdi-palette</v-icon>
    </app-system-bar-btn>
    <app-system-bar-btn
      v-if="isLogin"
      @click="closeWindow"
    >
      <v-icon>mdi-close</v-icon>
    </app-system-bar-btn>
    <!--    <v-spacer></v-spacer>-->
    <!--    <span>{{ date }}</span>-->
    <!--    <v-spacer></v-spacer>-->
    <!--    <span>{{ hijri }}</span>-->
    <!--    <v-spacer></v-spacer>-->
  </v-system-bar>
</template>

<script>

export default {
  name: 'SystemBar',
  data () {
    return {
      hijri: null,
      date: null,
      time: null,
      day: null,
      spanClass: 'pe-2'
    }
  },
  computed: {
    getClasses () {
      return {
        primary: true,
        'darken-2': this.themeLight,
        'darken-1': this.themeDark
      }
    }
  },
  created () {
    let time, date, day, hijri
    setInterval(() => {
      time = this.$moment().format('hh:mm:ss a')
      date = this.$moment().format('YYYY/MM/DD')
      day = this.$moment().format('ddd')
      hijri = this.$moment().format('iYYYY/iMM/iDD')

      this.date = `${date}`
      this.hijri = `${hijri}`
      this.time = time
      this.day = day
    }, 1000)
  },
  methods: {
    closeWindow () {
      this.confirmMessage(this.$t('messages.sure_logout'), () => {
        this.logoutUser(!0)
      })
    }
  }
}
</script>

<style scoped></style>
