/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  title: 'إعادة تعيين كلمة المرور',
  subtitle: 'قم بادخال اسم المستخدم لإرسال رمز إعادة تعيين كلمة المرور.',
  notSent: 'لم تحصل على الرمز؟ ',
  sendAgain: 'ارسال مرة أخرى',
  codeTitle: 'رمز تحقق إعادة تعيين كلمة المرور',
  resetPasswordSubtitle: 'قم بإدخال كلمة المرور الجديدة',
  resetPasswordPlaceholder: 'تم ارسال رمز اعادة تعيين كلمة المرور اليك.',
  resendSpan: 'يمكنك اعادة ارسال الرمز بعد: '
}
