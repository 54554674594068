/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  data () {
    return {
      avatarObjectURL: null
    }
  },
  methods: {
    revokeAvatarUrl () {
      if (this.avatarObjectURL && URL) {
        try {
          URL.revokeObjectURL(this.avatarObjectURL)
        } catch (e) {

        }
      }
    }
  },
  computed: {
    blobAvatar () {
      if (this.getAvatarItem[this.getBlobAvatarName]) {
        this.avatarObjectURL = window.URL.createObjectURL(new Blob([this.getAvatarItem[this.getBlobAvatarName]]))
        return this.avatarObjectURL
      }
      return this.getAvatarItem[this.getAvatarName]
    },
    getAvatarItem () {
      return this.selectedItem
    },
    getBlobAvatarName () {
      return 'blobAvatar'
    },
    getAvatarName () {
      return 'avatar'
    }
  },
  beforeDestroy () {
    this.revokeAvatarUrl()
  }
}
