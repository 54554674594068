/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import moment from 'moment-hijri'
import Vue from 'vue'
import config from '@app/config'

Vue.prototype.$moment = moment

moment.locale(config.locale.defaultLocale)

export default moment
