/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import Api from '@app/api'

export default {
  namespaced: true,
  state: {
    items: [],
    fetching: !1
  },
  getters: {
    getItems: s => s.items,
    getFetching: s => s.fetching
  },
  actions: {
    async fetchItems ({ commit, ...rest }, callback) {
      const onFinally = () => {
        commit('setFetching', !1)
        if (typeof callback === 'function') {
          callback()
        }
      }
      // console.log(rest.rootGetters['auth/isLogin'])
      if (!rest.rootGetters['auth/isLogin']) {
        onFinally()
        return
      }
      commit('setFetching', !0)
      Api.methods.user.sideMenu().then(({ _data: items, _success }) => {
        commit('setItems', items || [])
      }).catch(e => e).finally(() => {
        onFinally()
      })
    }
  },
  mutations: {
    setItems: (s, payload) => (s.items = payload),
    setFetching: (s, payload) => (s.fetching = payload)
  }
}
