<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-overlay
    v-if="AppSnackbar"
    z-index="999999"
  >
    <!--<component-->
    <!--    :is="getComponentName"-->
    <!--    v-if="AppSnackbar"-->
    <!--    :value="AppSnackbar"-->
    <!--    no-click-animation-->
    <!--    persistent-->
    <!--    scrollable-->
    <!--    transition-->
    <!--&gt;-->
    <!--</component>-->
    <v-snackbar
      v-model="AppSnackbar"
      :color="color"
      :content-class="contentClasses"
      :dark="themeDark"
      :elevation="elevation"
      :left="!AppIsSmall"
      :timeout="timeout"
      :transition="transition"
      :vvertical="AppIsSmall"
      max-width="300"
      top
      v-bind="$attrs"
      vertical
      v-on="$listeners"
    >
      <div class="text-start">
        <template v-if="iconStyle">
          <v-icon
            :color="iconColor"
            class="mx-2"
          >
            {{ iconStyle }}
          </v-icon>
        </template>
        <span
          style="white-space: break-spaces;word-wrap: break-word"
          v-html="appTextSnackbar"
        />
      </div>
      <template #action="{ attrs }">
        <template v-if="appConfirm">
          <snackbar-btn
            :color="btnColor"
            @click="closeSnackbar"
          >
            {{ $t('yes') }}
          </snackbar-btn>
          <snackbar-btn
            :color="btnColor"
            @click="cancelSnackbar"
          >
            {{ $t('no') }}
          </snackbar-btn>
        </template>
        <template v-else>
          <snackbar-btn
            :color="btnColor"
            @click="closeSnackbar"
          >
            {{ $t('done') }}
          </snackbar-btn>
        </template>
      </template>
    </v-snackbar>
  </v-overlay>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SnackbarBtn from './SnackbarBtn'
import { VDialog } from 'vuetify/lib/components/VDialog'

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers('snackbar')

export default {
  name: 'Snackbar',
  components: {
    // SnackbarBtn: () => import( /* webpackChunkName: "SnackbarBTN" */ './SnackbarBtn' )
    SnackbarBtn
  },
  data () {
    return {
      elevation: 24,
      SnackbarTimeout: undefined,
      transition: 'fade-transition'
    }
  },
  computed: {
    timeout () {
      return -1
      // return this.appConfirm ? -1 : (this.isToast ? 3000 : this.SnackbarTimeout)
      return this.appConfirm ? -1 : (this.SnackbarTimeout || 3000)
    },
    contentClasses () {
      return [
        // 'me-auto',
        // 'ms-0',
        'container',
        'text-start',
        // 'w-100',
        this.appTypeSnackbar === 'normal' ? 'black--text' : 'white--text'
      ].join(' ')
    },
    color () {
      return this.mapStyle[this.appTypeSnackbar].snackbar || ''
    },
    btnColor () {
      return this.mapStyle[this.appTypeSnackbar].btnColor
    },
    mapStyle () {
      return this.appTypesSnackbar
    },
    iconColor () {
      return this.mapStyle[this.appTypeSnackbar].iconColor || ''
    },
    iconStyle () {
      return this.mapStyle[this.appTypeSnackbar].icon || ''
    },
    AppSnackbar: {
      set (value) {
        mapMutations(['setShow']).setShow.call(this, value)
      },
      get () {
        return mapGetters(['getShow']).getShow.call(this)
      }
    },
    appTextSnackbar: {
      set (value) {
        mapMutations(['setText']).setText.call(this, value)
      },
      get () {
        return mapGetters(['getText']).getText.call(this)
      }
    },
    appTypeSnackbar: {
      set (value) {
        mapMutations(['setType']).setType.call(this, value)
      },
      get () {
        return mapGetters(['getType']).getType.call(this)
      }
    },
    appTypesSnackbar () {
      return mapGetters(['getTypes']).getTypes.call(this)
    },
    appCallbackSnackbar: {
      set (value) {
        mapMutations(['setCallback']).setCallback.call(this, value)
      },
      get () {
        return mapGetters(['getCallback']).getCallback.call(this)
      }
    },
    appConfirm: {
      set (value) {
        mapMutations(['setConfirm']).setConfirm.call(this, value)
      },
      get () {
        return mapGetters(['getConfirm']).getConfirm.call(this)
      }
    },
    appCallbackReject: {
      set (value) {
        mapMutations(['setReject']).setReject.call(this, value)
      },
      get () {
        return mapGetters(['getReject']).getReject.call(this)
      }
    },
    isToast () {
      return mapGetters(['getToast']).getToast.call(this)
    },
    getComponentName () {
      return this.isToast ? 'span' : VDialog
      // return this.isToast ? 'span' : 'v-dialog'
      // return 'span'
    }
  },
  watch: {
    AppSnackbar (n, o) {
      n === false && o === true && this.closeSnackbar()
    }
  },
  methods: {
    cancelSnackbar () {
      if (this.AppSnackbar === true) {
        this.AppSnackbar = false
        if (this.appCallbackReject) {
          this.appCallbackReject.call(this)
        }
        this.$nextTick(() => this.closedSnackbar())
      }
    },
    closeSnackbar () {
      if (this.AppSnackbar === !0) {
        this.AppSnackbar = false
        if (this.appCallbackSnackbar) {
          this.appCallbackSnackbar.call(this)
        }
        this.$nextTick(() => this.closedSnackbar())
      }
    },
    closedSnackbar () {
      this.$nextTick(() => mapActions(['hideSnackbar']).hideSnackbar.call(this))
    }
  }
}
</script>

<style scoped>

</style>
