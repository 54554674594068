<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-row dense>
    <v-col cols="12">
      <slot name="before" />
    </v-col>
    <v-col cols="12">
      <!--<v-row>
        <app-countries
          v-model="form.country_id"
          clearable
          cols="12"
          name="country_id"
          sm="6"
          @change="changeCountriesMixin($event)"
          @set-items="setCountriesMixin($event)"
        />
        <app-auto-select
          v-model="form.city_id"
          :items="getCitiesMixin"
          clearable
          cols="12"
          name="city_id"
          sm="6"
        />
      </v-row>
      <v-row>
        <app-nationalities
          v-model="form.nationality_id"
          cols="12"
          name="nationality_id"
          sm="6"
        />
        <app-genders
          v-model="form.gender_id"
          cols="12"
          name="gender_id"
          sm="6"
        />
        <app-titles
          v-model="form.title_id"
          cols="12"
          name="title_id"
          sm="6"
        />
      </v-row>-->
      <v-row>
        <app-job-titles
          v-model="form.job_title_id"
          clearable
          cols="12"
          name="job_title_id"
          sm="6"
        />
        <app-branches
          v-model="form.branch_id"
          clearable
          cols="12"
          name="branch_id"
          sm="6"
        />
      </v-row>
      <v-row>
        <slot />
      </v-row>
    </v-col>
    <v-col cols="12">
      <slot name="after" />
    </v-col>
  </v-row>
</template>
<script>

import { CountryMixin } from '@mixins'
import SignatureForm from '@components/user/SignatureForm'

export default {
  name: 'PersonalForm',
  components: { SignatureForm },
  mixins: [CountryMixin],
  props: {
    value: {
      type: Object,
      default: () => undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    form: {
      set (v) {
        this.$emit('input', v)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>
