/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  name: 'إعداد المنصة',
  general: 'عام',
  notifications: 'التنبيهات',
  contact: 'التواصل'
}
