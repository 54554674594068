/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  loginSubtitle: 'مرحباً بك',
  forgetPassword: 'نسيت كلمة المرور؟',
  no_account: 'ليس لديك حساب؟ ',
  create_account: 'أنشئ حسابك الآن!'
}
