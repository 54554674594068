/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  computed: {
    getItemForm () {
      // console.log(this.selectedItem)
      return this.selectedItem
    },
    passRule () {
      const r = ['confirmed:password_confirmation']
      !this.getItemForm?.id && r.push('required')
      return r.join('|')
    },
    passConfirmRule () {
      const r = ['confirmed:password']
      !this.getItemForm?.id && r.push('required')
      return r.join('|')
    }
  }
}
