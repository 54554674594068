/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  datatable_search: 'اكتب للبحث...',
  username: 'اسم مستخدم الدخول',
  name: 'اسمك الكامل',
  mobile: 'رقم الجوال الخاص بك',
  organization_name: 'إسم الشركة أو الاسم التجاري',
  commercial_register: 'الرقم التجاري للمنشأة/الشركة/فرد',
  vat_number: 'الرقم الضريبي في دولتك',
  specifications_hint: 'بعد إضافة المواصفات يمكنك التعديل وارفاق الصور، فيديو أو مستندات للعميل'
}
