/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  id: 'ID',
  login_id: 'Login ID',
  name: 'Name',
  name_ar: 'Arabic Name',
  name_en: 'English Name',
  short_name: 'Short Name',
  password: 'Password',
  password_confirmation: 'Confirm Password',
  email: 'Email',
  mobile: 'Mobile',
  phone: 'Phone',
  whatsapp: 'Whatsapp',
  code: 'Code',
  two_factor_auth: 'Two Factor Auth',
  user_id: 'User',
  roles_id: 'Roles',
  permissions_id: 'Permissions',
  from_date: 'From date',
  to_date: 'To date',
  description: 'Description',
  description_ar: 'Arabic Description',
  description_en: 'English Description',
  active: 'Active',
  date: 'Date',
  token: 'Token',
  type: 'Type',
  status: 'Status',
  push_token: 'Device push token',
  verification_code: 'Verification Code',
  attachments: 'Attachments',
  attachment: 'Attachment',
  ip_address: 'Ip address',
  user_agent: 'User agent',
  send_time: 'Send Time',
  username: 'Username',
  locale: 'Locale',
  app_name: 'App name',
  app_name_ar: 'Arabic App name',
  app_name_en: 'English App name',
  notification_email: 'Email notifications',
  notification_mobile: 'Mobile notifications',
  web: 'Website url',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'Customer service email',
  customer_service_mobile: 'Customer service mobile',
  customer_service_phone: 'Customer service phone',
  google_map_url: 'google map url',
  app_store_url: 'App Store url',
  play_store_url: 'Play Store url',
  address: 'Address',
  address_ar: 'Arabic address',
  address_en: 'English address',
  contact_us: 'Contact us',
  contact_us_ar: 'Arabic Contact us',
  contact_us_en: 'English Contact us',
  about_us: 'About us',
  about_us_ar: 'Arabic About us',
  about_us_en: 'English About us',
  privacy_policy: 'Privacy & Policy',
  privacy_policy_ar: 'Arabic Privacy & Policy',
  privacy_policy_en: 'English Privacy & Policy',
  terms_and_conditions: 'Terms & Conditions',
  terms_and_conditions_ar: 'Arabic Terms & Conditions',
  terms_and_conditions_en: 'English Terms & Conditions',
  commercial_register: 'Commercial Register',
  commercial_name: 'Commercial name',
  commercial_name_ar: 'Arabic Commercial name',
  commercial_name_en: 'English Commercial name',
  vat_number: 'VAT number',
  notification_methods: 'Notification Methods',
  avatar: 'Avatar',
  order_by: 'Order by',
  key: 'Key',
  iso: 'ISO',
  iso2: 'ISO-2',
  iso3: 'ISO-3',
  country_id: 'Country',
  created_at: 'Created date',
  from_created_at: 'From Created At',
  to_created_at: 'To Created At',
  message: 'Your message',
  city_id: 'City',
  role_id: 'Role',
  price: 'Price',
  notes: 'Notes',
  icon: 'Icon',
  latitude: 'Latitude',
  longitude: 'Longitude',
  subject: 'Subject',
  image: 'Image',
  images: 'Images',
  choose_image: 'Choose Image',
  update_image: 'Update Image',
  blobAvatar: 'Avatar',
  url: 'Url',
  amount: 'Amount',
  discount: 'Discount',
  discount_type: 'Discount Type',
  discount_amount: 'Discount Amount',
  meta: 'Meta',
  quantity: 'Quantity',
  location: 'Location',
  tax: 'TAX',
  payable_id: 'Payable ID',
  payable_type: 'Payable Type',
  total: 'Total',
  data: 'Data',
  from_discount_amount: 'Discount Amount From',
  to_discount_amount: 'Discount Amount To',
  expire_date: 'Expire Date',
  from_expire_date: 'Expire Date From',
  to_expire_date: 'Expire Date To',
  read: 'read',
  ref_key: 'Reference ID',
  attachment_type: 'Attachment Type',
  percentage: 'Percentage',
  vat: 'Vat',
  vat_amount: 'Vat Amount',
  payment_method_id: 'Payment Method',
  gender_id: 'Gender',
  nationality_id: 'Nationality',
  payment_date: 'Payment Date',
  payment_type: 'Payment Type',
  paid_amount: 'Paid Amount',
  color: 'Color',
  symbol: 'Symbol',
  contact: 'Contact',
  start_date: 'Start Date',
  from_start_date: 'From Start_date',
  to_start_date: 'To_start Date',
  end_date: 'End_date',
  from_end_date: 'From End_date',
  to_end_date: 'To_end Date',
  start_time: 'Start Time',
  from_start_time: 'From Start_time',
  to_start_time: 'To_start Time',
  end_time: 'End_time',
  from_end_time: 'From End_time',
  to_end_time: 'To_end Time',
  currency_id: 'Currency',
  title_id: 'Title',
  bank_id: 'Bank',
  database: 'App',
  mail: 'E-mail',
  sms: 'SMS',
  rate: 'Rate',
  pay_date: 'Pay Date',
  from_pay_date: 'From Pay Date',
  to_pay_date: 'To Pay Date',
  payment_id: 'Payment',
  invoice_id: 'Invoice',
  due_amount: 'Due Amount',
  expire_days: 'Expire Days',
  quotation_id: 'Quotation',
  quotation_item_id: 'Quotation Item',
  supplying_duration_id: 'Supplying Duration',
  brand_id: 'Brand',
  quotation_terms: 'Quotation Terms',
  quotation_terms_ar: 'Arabic Quotation Terms',
  quotation_terms_en: 'English Quotation Terms',
  organization_name: 'Organization Name',
  customer_name: 'Customer Name',
  customer_id: 'Customer',
  specifications: 'Specifications',
  specifications_ar: 'Arabic Specifications',
  specifications_en: 'English Specifications',
  exterior_color: 'Exterior Color',
  interior_color: 'Interior Color',
  model: 'Model',
  plates: 'Paintings and feedback',
  insurance: 'Insurance',
  quotation_items: 'Quotation Items',
  brand_item_id: 'Item',
  color_id: 'Color',
  job_title_id: 'Job Title',
  traffics_licence: 'Traffic Licence',
  quotation_term_id: 'Quotation Term',
  branch_id: 'Branch',
  signature: 'Signature',
  vin: 'VIN',
  stamp: 'Stamp',
  item_specifications: 'Specifications',
  youtube_url: 'Youtube URL',
  video: 'Video',
  paid: 'Paid',
  paid_at: 'Paid Date',
  from_paid_at: 'Paid Date From',
  to_paid_at: 'Paid Date To',
  invoice_item_id: 'Item',
  country_of_origin: 'Country Of Origin',
  total_after_discount: 'Total After Discount',
  decimal_name: 'Decimal Name',
  decimal_name_ar: 'Arabic Decimal Name',
  decimal_name_en: 'English Decimal Name'
}
