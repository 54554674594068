/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import { GetHeadersMixin } from '@mixins'
import { debounce } from 'lodash'
import { hideHtmlOverflow, showHtmlOverflow } from '@helpers/Tools'

const defItemsPerPage = () => 20

export default {
  name: 'Datatable',
  mixins: [GetHeadersMixin],
  props: {
    infiniteItems: {
      type: Boolean,
      default: () => undefined
    },
    auth: {
      type: Boolean,
      default: () => !0
    },
    fetchMethod: {
      type: [String, Function],
      default: () => 'get',
      validator: function (value) {
        return ['get', 'post', 'GET', 'POST'].indexOf(value) !== -1
      }
    },
    headers: {
      type: Array,
      required: !0
    },
    url: {
      type: [String, Function],
      default: () => undefined
    },
    itemKey: {
      type: String,
      default: () => 'id'
    },
    itemKeyWidth: {
      type: [String, Number],
      default: () => '6%'
    },
    controlKey: {
      type: String,
      default: () => 'control'
    },
    controlWidth: {
      type: [String, Number],
      default: () => '20%'
    },
    fixedHeader: {
      type: Boolean,
      default: () => !0
    },
    footer: {
      type: Object,
      default () {
        return {
          class: 'asd',
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          'items-per-page-options': [defItemsPerPage(), 50, 200, 500, -1]
        }
      }
    },
    showSelect: {
      type: Boolean,
      default: () => undefined
    },
    singleSelect: {
      type: Boolean,
      default: () => !1
    },
    dense: {
      type: Boolean,
      default: () => !0
    },
    multiSort: {
      type: Boolean,
      default: () => !0
    },
    search: {
      type: Boolean,
      default: () => !0
    },
    center: {
      type: Boolean,
      default: () => !0
    },
    minHeight: {
      type: [Number, String],
      default: () => 300
    },
    height: {
      type: [Number, String],
      default () {
        // console.log(this.$vuetify)
        return 500
      }
    },
    maxHeight: {
      type: [Number, String],
      default: () => 450
    },
    loaderHeight: {
      type: [Number, String],
      default: () => 6
    },
    formDialog: {
      type: Boolean,
      default: () => undefined
    },
    formDialogLoading: {
      type: Boolean,
      default: () => false
    },
    btnDialog: {
      type: Boolean,
      default: () => undefined
    },
    btnDialogRoute: {
      type: [String, Object],
      default: () => undefined
    },
    modalTitle: {
      type: String,
      default: () => 'store'
    },
    modalBtnText: {
      type: String,
      default: () => 'create'
    },
    modalBtnIcon: {
      type: String,
      default: () => 'add'
    },
    pdf: {
      type: Boolean,
      default: () => undefined
    },
    excel: {
      type: Boolean,
      default: () => undefined
    },
    fullscreen: {
      type: Boolean,
      default: () => !0
    },
    calculateWidths: {
      type: Boolean,
      default: () => true
    },
    hideTopSlot: {
      default: () => !1
    },
    onClickModalActivator: {
      type: Function,
      default: () => undefined
    }
  },
  data () {
    return {
      ready: !1,
      clearedRouteFilter: !1,
      itemsToShow: defItemsPerPage(),
      datatableOptions: {},
      datatableFilters: {},
      items: [],
      selectedItems: undefined,
      pageCount: undefined,
      serverItemsLength: undefined,
      loading: !1,
      loadingPdf: !1,
      loadingExcel: !1,
      modal: !1,
      filterDialog: {
        value: !1,
        open: () => {
          this.filterDialog.value = !0
        },
        close: () => {
          this.filterDialog.value = !1
        }
      }
    }
  },
  watch: {
    datatableOptions: {
      handler: debounce(function (v) {
        this.$nextTick(() => this.fetchData())
      }, 300),
      deep: true
      // immediate: true,
    },

    datatableFilters: {
      handler: debounce(function () {
        this.$nextTick(() => this.fetchData())
      }, 300),
      deep: true
    },

    modal (v) {
      if (v === !0) {
        hideHtmlOverflow()
      }
      if (v === !1) {
        showHtmlOverflow()
      }
      // console.log(v)
      this.$emit('update:formDialog', v)
    },

    formDialog (v) {
      this.modal = v
    }
  },
  computed: {
    infiniteScroll () {
      if (this.infiniteItems !== undefined) return this.infiniteItems

      return !1
      // return this.AppIsSmall
    },

    showDatatableComponent () {
      if (this.auth) {
        return Boolean(this.authUser)
      }
      return !0
    },

    hasRouteFilter () {
      return !this.clearedRouteFilter && Object.keys(this.$route.params?.datatableFilters || {}).length > 0
    },

    getDatatableClasses () {
      return 'app-datatable'
    },

    computedUrlDatatable () {
      return this.getUrl('url')
    },

    getFetchMethod () {
      return this.$helpers.isFunction(this.fetchMethod) ? this.fetchMethod() : this.fetchMethod
    },

    getRequestDatatableHeaders () {
      return this.getHeaders.filter(h => h.value !== this.controlKey)
    },

    getScopedSlots () {
      return Object.keys(this.$scopedSlots).filter(i => ['top', 'formDialog', 'filter'].indexOf(i) < 0)
    },

    selectedIds () {
      const items = this.selectedItems || []
      return items.map((e) => e[this.itemKey])
    },

    hasTopSlot () {
      if (this.hideTopSlot) return false
      return this.search || this.$scopedSlots.filter || this.$scopedSlots.top || this.hasDialog
    },

    hasFilterSlot () {
      return this.$scopedSlots.filter
    },

    canPdf () {
      // return false
      return this.pdf === !0
    },

    canExcel () {
      return this.excel === !0
    },

    getModalBtnText () {
      return this.hasDialog && this.modalBtnText ? this.parseAttribute(this.modalBtnText) : null
    },

    getModalBtnIcon () {
      return this.hasDialog && this.modalBtnIcon ? this.modalBtnIcon : null
    },

    getModalBtnTooltip () {
      return this.getModalTitle
    },

    getModalTitle () {
      if (!this.hasDialog || !this.modalTitle) return null
      const title = this.modalTitle
      let n = this.getPageTitle(1)
      // console.log(title,n)
      if (title === 'store' || title === 'update' || title === 'show') {
        title === 'store' && (n = this.parseArabicTitle(n))
        const text = this.$t(`replace.${title}`, { n })
        // console.log(title)
        try {
          // let y
          // if (n && (y = n.split(' ')) && y.length > 0 && y[0].substr(-1) === 'ة' && text.substr(-1) !== 'ة') {
          //   text += 'ة'
          // }
        } catch (e) {

        }
        return text
      }
      console.log(this.modalTitle)
      return this.parseAttribute(this.modalTitle)
    },

    hasDialog () {
      return this.formDialog !== undefined
    },

    hasBtnDialog () {
      return this.btnDialog === false ? false : this.$scopedSlots.formDialog
    },

    datatableListeners () {
      const vm = this
      return Object.assign({}, this.$listeners,
        {
          refresh: (...args) => vm.refresh(...args)
        })
    },

    slotListeners () {
      const vm = this
      return {
        refresh: (...args) => vm.refresh(...args)
      }
    },

    slotBind () {
      return {
        ...this.$attrs,
        datatableOptions: this.getDatatableOptions(),
        datatableFilters: this.getDatatableFilters(),
        on: this.slotListeners
      }
    },

    getFullscreen () {
      return this.fullscreen === undefined ? this.AppIsSmall : this.fullscreen
    },

    getSelectedLength () {
      return this.selectedItems ? (this.selectedItems.length || null) : null
    },

    getShowSelect () {
      if (this.showSelect === undefined) {
        // !this.canExcel && !this.canPdf && this.showSelect === undefined ? false :
        return this.canExcel || this.canPdf
      }
      return this.showSelect
    }
  },
  methods: {
    clearRouteFilters () {
      this.clearedRouteFilter = !0
      this.datatableFilters = {}
    },

    clickModalActivator () {
      if (this.btnDialogRoute) {
        let route = this.btnDialogRoute
        if (typeof this.btnDialogRoute === 'string') {
          route = this.route(route)
        }
        this.navigate(route?.name, route?.params)
        return
      }
      this.modal = !this.modal
      this.onClickModalActivator && this.onClickModalActivator()
    },

    async refresh () {
      this.serverItemsLength = undefined
      this.itemsToShow = defItemsPerPage()
      this.clearSelectedItems()
      this.clearItems()
      this.datatableOptions.page = 1
      this.$nextTick(async () => {
        await this.fetchData()
      })
    },

    getDatatableOptions () {
      const o = {
        ...this.datatableOptions
      }
      if (this.infiniteScroll) {
        o.itemsPerPage = defItemsPerPage()
      }
      return o
    },

    getDatatableFilters () {
      return this.datatableFilters
    },

    getDatatableItems () {
      return this.items
    },

    setDatatableItems (items) {
      this.items = items
    },

    mergeDatatableItems (items) {
      this.items = [...items, ...this.items]
    },

    updateDatatableItem (item, index) {
      const items = [...this.items]
      items[index] = { ...items[index], ...item }
      this.$nextTick(() => {
        this.items = [...items]
      })
    },

    setDatatableFilters (value = {}) {
      this.datatableFilters = { ...this.datatableFilters, ...value }
    },

    getStringifyOptions (params = {}) {
      const URLSearchParams = this.$helpers.queryStringify({ ...this.getDatatableOptions() })
      Object.keys(params).forEach((value) => {
        URLSearchParams.append(value, params[value])
      })
      return URLSearchParams
    },

    getUrl (indexType = 'url') {
      let url = this[indexType]
      if (!url) return url

      if (url === !0 || url === !1) {
        url = ''
      }
      // if (url === undefined) return url
      const params = this.getStringifyOptions({ indexType })
      // console.log(url)
      const uri = this.$helpers.isFunction(url)
        ? url(this.getDatatableOptions(), params)
        : (!url
            ? (this.$helpers.isFunction(this.url) ? this.url(this.getDatatableOptions(), params) : this.url)
            : url
          )
      // console.log(uri)

      const u = uri.split('?')
      const k = `indexType=${indexType}`

      if (!u.length) return uri

      if (u.length > 1) {
        u[u.length - 1].indexOf('indexType') === -1 && (u[u.length - 1] = `${u[u.length - 1]}&${k}`)
      } else {
        u.push(k)
      }

      return u.join('?')
    },

    getRequest (method = 'get', url = undefined, indexType = 'url') {
      url = url || this.getUrl(indexType)
      const body = this.getRequestData({ indexType })
      const data = method.toLowerCase() === 'post' ? body : {}
      const params = method.toLowerCase() === 'get' ? body : {}

      // const responseType = indexType === 'url' ? 'json' : 'arraybuffer';
      // const responseType = indexType === 'url' ? 'json' : 'blob'
      let responseType = 'json'
      if (['pdf', 'excel'].indexOf(indexType) !== -1) {
        if (this.AppIsSmall) {
          params.toUrl = 1
        } else {
          responseType = 'blob'
        }
      }

      responseType = 'json'
      params.toUrl = 1

      const config = {
        url,
        method,
        data,
        params,
        responseType
      }
      return this.$axios.request(config)
    },

    getRequestParams (params = {}) {
      const options = this.getDatatableOptions()
      const filter = this.getDatatableFilters()
      const pageTitle = this.getPageTitle()
      return { pageTitle, ...options, filter, ...params }
    },

    getRequestData (params = {}) {
      const query = this.$route.query || {}
      const data = this.getRequestParams()
      data.headerItems = this.getRequestDatatableHeaders
      data.items = this.selectedIds
      // console.log(this.$route)
      return { ...query, ...data, ...params }
    },

    fetchData () {
      // console.log(this.canFetchMore(), this.serverItemsLength)
      // if (this.loading || !this.getUrl('url') || !this.canFetchMore()) return
      if (this.loading || !this.getUrl('url')) return
      // console.log(this.datatableOptions)

      this.loading = !0
      if (!this.infiniteScroll) {
        this.clearSelectedItems()
        this.clearItems()
      }

      return new Promise((resolve, reject) => {
        const action = this.getRequest(this.getFetchMethod)
        if (!action) return resolve(action)
        action.then(response => {
          const { _data, _meta } = response || {}
          this.serverItemsLength = _meta?.total || 0
          this.$nextTick(() => {
            if (this.infiniteScroll) {
              this.items = [...this.items, ...(_data || [])]
            } else {
              this.items = _data || []
            }
          })
          resolve(response)
          return response
        }).catch(error => {
          reject(error)

          if (error.response?.status === 403) {
            this.navigate(this.APP_ROUTES.user.home)
          }
          return error
        })
      }).catch(e => e).finally(() => {
        // console.log(this.authUser)
        this.loading = !1
      })
    },

    getExportPromise (method = 'get', url, indexType) {
      return (new Promise((resolve, reject) => {
        const action = this.getRequest(method, url, indexType)
        action.then(response => {
          // console.log(response)
          // return
          // this.$helpers.downloadFromResponse(response)
          this.downloadFromResponse(response)
          this.clearSelectedItems()
          resolve(response)
          return response
        }).catch(error => {
          reject(error)
          return error
        })
      }))
    },

    exportPdf () {
      const indexType = 'pdf'
      const url = this.getUrl(indexType)
      if (!url || this.loadingPdf) return

      const promise = () => {
        this.loadingPdf = !0
        this.getExportPromise('get', url, indexType).finally(() => (this.loadingPdf = !1))
      }

      if (this.selectedIds.length < 1) {
        return this.confirmMessage(this.$t('messages.exportAll'), () => promise(), () => (this.loadingPdf = !1), 'warning')
      } else {
        return promise()
      }
    },

    exportExcel () {
      const indexType = 'excel'
      const url = this.getUrl(indexType)
      if (!url || this.loadingExcel) return

      const promise = () => {
        this.loadingExcel = !0
        this.getExportPromise('get', url, indexType).finally(() => (this.loadingExcel = !1))
      }

      if (this.selectedIds.length < 1) {
        return this.confirmMessage(this.$t('messages.exportAll'), () => promise(), () => (this.loadingExcel = !1), 'warning')
      } else {
        return promise()
      }
    },

    clearSelectedItems () {
      this.selectedItems = undefined
    },

    clearItems () {
      this.items = []
    },

    closeDialog () {
      this.modal = !1
    },

    canFetchMore () {
      if (this.serverItemsLength === undefined || !this.infiniteScroll) return !0
      return this.items.length < this.serverItemsLength
    },

    onScroll (e) {
      if (!this.infiniteScroll) return

      if (this.bottomVisible() && !this.loading && this.canFetchMore()) {
        // console.log(this.datatableOptions)
        ++this.datatableOptions.page
        this.itemsToShow += this.datatableOptions.itemsPerPage
      }
    }
  },
  beforeCreate () {
    this.$on('refresh', (...e) => this.refresh(...e))
  },
  mounted () {
    if (this.$route.params?.datatableFilters) {
      this.datatableFilters = {
        ...this.datatableFilters,
        ...this.$route.params.datatableFilters
      }
    }

    /* let f = this.$route.query?.filters
    if(f) {
      this.datatableFilters = {
        ...this.datatableFilters,
      }
    } */
    let datatableOptions = {}
    let updateDatatableOptions = !1
    if (this.$route.params?.datatableSearch) {
      datatableOptions = {
        ...datatableOptions,
        search: this.$route.params.datatableSearch
      }
      !updateDatatableOptions && (updateDatatableOptions = !0)
    }
    if (this.$route.query?.search) {
      datatableOptions = {
        ...datatableOptions,
        search: this.$route.query?.search
      }
      !updateDatatableOptions && (updateDatatableOptions = !0)
    }
    updateDatatableOptions && (this.datatableOptions = {
      ...this.datatableOptions,
      datatableOptions
    })

    this.items = []
    this.ready = !0
    // this.$nextTick(() => {
    // setTimeout(() => {
    // if (this.auth && !this.authUser && !this.isRoute(this.APP_ROUTES.homePage)) {
    //   this.$nextTick(async () => {
    // await this.$router.replace({ name: this.APP_ROUTES.homePage })
    // })
    // }
    // }, 90)
    // })
    this.$nextTick(() => this.fetchData())
    // console.log(2)
  }
}
