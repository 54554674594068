<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-list
    :dense="!AppIsSmall"
    class="pa-0"
    tile
  >
    <v-list-item-group v-model="AppLocale">
      <v-list-item
        v-for="(item, i) in AppLocales.filter(e=>e.code !== AppLocale)"
        :key="i"
        :disabled="item.code === AppLocale"
        :value="item.code"
        @click="SetAppLocale(item.code)"
      >
        <v-list-item-icon v-if="icon">
          <v-icon>mdi-translate</v-icon>
        </v-list-item-icon>
        <v-list-item-title :class="{'text-center': center}">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'LanguageList',
  props: {
    icon: {
      type: Boolean,
      default: () => !1
    },
    center: {
      type: Boolean,
      default: () => !1
    }
  }
}
</script>
